import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer, { DrawerProps } from 'src/component/Drawer';
import LanguageMenu from 'src/component/LanguageMenu';
import Body from 'src/component/typography/Body';
import IcClose from 'src/image/ic-close.svg';
import IcDown from 'src/image/ic-down-s.svg';

type NavbarDrawerProps = DrawerProps & {
  onClose: () => void;
};

const NavbarDrawer = ({ open, onClose }: NavbarDrawerProps) => {
  const { t } = useTranslation();
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const isLangMenuOpen = Boolean(langMenuAnchorEl);

  const handleLangMenuClick = (event: MouseEvent<HTMLDivElement>) => {
    if (isLangMenuOpen) setLangMenuAnchorEl(null);
    else setLangMenuAnchorEl(event.currentTarget);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <img className="ml-auto w-[30px] h-[30px]" onClick={onClose} src={IcClose} />
      <div className="flex px-2 py-4">
        <Body size="m" bold onClick={handleLangMenuClick}>
          {t('welcome:language')}
        </Body>
        <img src={IcDown} />
      </div>
      <LanguageMenu
        open={isLangMenuOpen}
        anchorEl={langMenuAnchorEl}
        onClose={() => setLangMenuAnchorEl(null)}
      />
    </Drawer>
  );
};

export default NavbarDrawer;
