import { Fade, Popover } from '@mui/material';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useRef, useState } from 'react';
import ReactCalendar from 'react-calendar';
import { useMediaPredicate } from 'react-media-hook';
import { MediaQuery } from 'src/constant/Media';
import IcCalendar from 'src/image/dashboard/ic-calendar.svg';
import Input from './Input';

type Props = {
  label?: string;
  tailIcon?: boolean;
  defaultDate?: Date;
  valueDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  onDateChanged?: (date: Date) => void;
  className?: string;
};

const Calendar = ({
  label,
  tailIcon = false,
  defaultDate = new Date(Date.now()),
  valueDate,
  minDate,
  maxDate,
  onDateChanged,
  className,
}: Props) => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [componentDate, setComponentDate] = useState<Date>(defaultDate);
  const isBiggerThanXsm = useMediaPredicate(MediaQuery.Xsm);
  const inputRef = useRef<HTMLDivElement>(null);
  const selectedDate = valueDate ? valueDate : componentDate;

  const onDateClick = (date: Date) => {
    setComponentDate(date);
    setShowCalendar(false);
    onDateChanged && onDateChanged(date);
  };

  const onInputClick = () => {
    setShowCalendar(!showCalendar);
  };

  const onPopoverClose = () => {
    setShowCalendar(false);
  };

  return (
    <div className={classNames('relative', className)}>
      <div ref={inputRef}>
        <Input
          type="text"
          label={label}
          value={format(selectedDate, 'yyyy-MM-dd')}
          readOnly={true}
          onClick={onInputClick}
        />
      </div>
      {tailIcon && <img className="absolute bottom-2 right-2" src={IcCalendar} />}
      <Popover
        id="calendar"
        open={showCalendar}
        anchorEl={inputRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={onPopoverClose}
        classes={{
          paper: classNames('mt-1 !bg-transparent', {
            '!top-1/2 !left-1/2 !-translate-x-1/2 !-translate-y-1/2': !isBiggerThanXsm,
          }),
        }}
        BackdropProps={{ invisible: isBiggerThanXsm }}
        TransitionComponent={Fade}
      >
        <ReactCalendar
          onChange={onDateClick}
          defaultValue={new Date(Date.now())}
          minDate={minDate}
          maxDate={maxDate}
          value={selectedDate}
          locale="en-US"
        />
      </Popover>
    </div>
  );
};

export default Calendar;
