import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';

const Dashboard = () => (
  <>
    <Navbar className="h-[72px]" />
    <Outlet />
  </>
);

export default Dashboard;
