import {
  GetBalanceResponse,
  GetBalancesResponse,
  PatchBalancesSetRequest,
  PatchBalancesSetResponse,
  PatchBalancesUnsetRequest,
  PatchBalancesUnsetResponse,
} from 'src/model/Api';
import { handleError } from 'src/util/errorHandler';
import http from 'src/util/http';

const getBalances = async (account: string) => {
  try {
    return await http.authGet<GetBalancesResponse>(`${account}/balances`);
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

const getBalance = async (account: string, currency: string) => {
  try {
    return await http.authGet<GetBalanceResponse>(`${account}/balances/${currency}`);
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

const patchBalancesSet = async (
  account: string,
  currency: string,
  data: PatchBalancesSetRequest,
) => {
  try {
    return await http.authPatch<PatchBalancesSetResponse, PatchBalancesSetRequest>(
      `${account}/balances/${currency}/set`,
      { data },
    );
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

const patchBalancesUnset = async (
  account: string,
  currency: string,
  data: PatchBalancesUnsetRequest,
) => {
  try {
    return await http.authPatch<PatchBalancesUnsetResponse, PatchBalancesUnsetRequest>(
      `${account}/balances/${currency}/unset`,
      { data },
    );
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

export default {
  getBalances,
  getBalance,
  patchBalancesSet,
  patchBalancesUnset,
};
