import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Popover, { PopoverProps } from 'src/component/Popover';
import Body from 'src/component/typography/Body';
import H5 from 'src/component/typography/H5';
import { Auth } from 'src/constant/Page';

type Props = { onClose: () => void } & PopoverProps;

const NavbarMenu = ({ onClose, ...props }: Props) => {
  const navigate = useNavigate();

  return (
    <Popover onClose={onClose} {...props}>
      <H5 className="px-6 py-2">pikachu@xxxmail.com</H5>
      <Body className="px-6 py-2 cursor-pointer">Security</Body>
      <Divider />
      <Body className="px-6 py-2 cursor-pointer" onClick={() => navigate(Auth.Root + Auth.SignOut)}>
        Logout
      </Body>
    </Popover>
  );
};

export default NavbarMenu;
