import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Drawer, { DrawerProps } from 'src/component/Drawer';
import LanguageMenu from 'src/component/LanguageMenu';
import Body from 'src/component/typography/Body';
import H4 from 'src/component/typography/H4';
import { Auth } from 'src/constant/Page';
import IcClose from 'src/image/ic-close.svg';
import IcDown from 'src/image/ic-down-s.svg';

type NavbarRightDrawerProps = DrawerProps & {
  onClose: () => void;
};

const NavbarRightDrawer = ({ open, onClose }: NavbarRightDrawerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const isLangMenuOpen = Boolean(langMenuAnchorEl);

  const handleLangMenuClick = (event: MouseEvent<HTMLDivElement>) => {
    if (isLangMenuOpen) setLangMenuAnchorEl(null);
    else setLangMenuAnchorEl(event.currentTarget);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <img className="ml-auto w-[30px] h-[30px]" onClick={onClose} src={IcClose} />
      <H4 className="my-4 p-2">pickchu@xxxmail.com</H4>
      <Body bold size="m" className="px-2 py-4">
        Security
      </Body>
      <div className="flex px-2 py-4">
        <Body size="m" bold onClick={handleLangMenuClick}>
          {t('language')}
        </Body>
        <img src={IcDown} />
      </div>
      <Body bold size="m" className="px-2 py-4" onClick={() => navigate(Auth.Root + Auth.SignOut)}>
        Log out
      </Body>
      <LanguageMenu
        open={isLangMenuOpen}
        anchorEl={langMenuAnchorEl}
        onClose={() => setLangMenuAnchorEl(null)}
      />
    </Drawer>
  );
};

export default NavbarRightDrawer;
