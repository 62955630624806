import { GetDepositsParams, GetDepositsResponse } from 'src/model/Api';
import { handleError } from 'src/util/errorHandler';
import http from 'src/util/http';

const getDeposits = async (account: string, currency: string, params?: GetDepositsParams) => {
  try {
    return await http.authGet<GetDepositsResponse>(`${account}/${currency}/deposits`, {
      params,
    });
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

export default {
  getDeposits,
};
