import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement> & {
  bold?: boolean;
  size?: 's' | 'm' | 'l';
};

const Body = ({ className, bold = false, size = 'l', ...props }: Props) => (
  <div
    className={classNames(
      {
        ['text-xs']: size === 's',
        ['text-sm']: size === 'm',
        ['text-base']: size === 'l',
        ['font-medium']: bold,
      },
      'leading-[1.5]',
      className,
    )}
    {...props}
  />
);

export default Body;
