import classNames from 'classnames';
import { HTMLAttributes, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import LanguageMenu from 'src/component/LanguageMenu';
import Body from 'src/component/typography/Body';
import { MediaQuery } from 'src/constant/Media';
import { Auth as AuthPage } from 'src/constant/Page';
import IcDown from 'src/image/ic-down-s.svg';
import IcLogo from 'src/image/ic-logo-bisa.svg';
import IcMenu from 'src/image/ic-menu.svg';
import NavbarDrawer from './NavbarDrawer';

type Props = HTMLAttributes<HTMLHeadingElement>;

const Navbar = ({ className, ...props }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const biggerThanXsm = useMediaPredicate(MediaQuery.Xsm);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const isLangMenuOpen = Boolean(langMenuAnchorEl);

  const handleLangMenuClick = (event: MouseEvent<HTMLDivElement>) => {
    if (isLangMenuOpen) setLangMenuAnchorEl(null);
    else setLangMenuAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div
        className={classNames(
          className,
          'flex mx-4 xs:mx-10 sm:mx-auto sm:w-[960px] xl:w-[1200px] justify-between items-center',
        )}
        {...props}
      >
        <img src={IcLogo} />
        <div className="flex items-center gap-6">
          <Button
            appearance="text"
            className="text-sm cursor-pointer text-primary-500"
            onClick={() => navigate(AuthPage.SignIn)}
          >
            {t('dashboard:auth.signIn.signIn')}
          </Button>
          <Button appearance="text" className="text-sm cursor-pointer text-primary-500">
            {t('dashboard:auth.signIn.register')}
          </Button>
          {biggerThanXsm ? (
            <div className="flex cursor-pointer" onClick={handleLangMenuClick}>
              <Body size="m">{t('welcome:language')}</Body>
              <img src={IcDown} />
            </div>
          ) : (
            <img src={IcMenu} onClick={() => setIsDrawerOpen(true)} />
          )}
        </div>
      </div>
      <LanguageMenu
        open={isLangMenuOpen}
        anchorEl={langMenuAnchorEl}
        onClose={() => setLangMenuAnchorEl(null)}
      />
      <NavbarDrawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    </>
  );
};

export default Navbar;
