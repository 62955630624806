import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLHeadingElement>;

const H1 = ({ className, ...props }: Props) => (
  <h1
    className={classNames('text-4xl leading-[1.2] font-bold sm:text-[56px]', className)}
    {...props}
  />
);

export default H1;
