import publicEndpoint from 'src/api/publicEndpoint';
import { updateCurrencies } from 'src/redux/publicSlice';
import { dispatch, getState } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';

export const loadCurrencies = async () => {
  const state = getState();
  if (state.public.currencies !== null) return state.public.currencies;

  try {
    dispatch(startWaiting());
    const res = await publicEndpoint.getSupportedCurrencies();

    dispatch(updateCurrencies(res.data));

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};
