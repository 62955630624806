import classNames from 'classnames';
import { format } from 'date-fns';
import { useState } from 'react';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import H6 from 'src/component/typography/H6';
import Subtitle from 'src/component/typography/Subtitle';
import IcArrowDown from 'src/image/dashboard/ic-arrow-down.svg';
import { Withdrawal } from 'src/model/Withdrawal';

export type Props = {
  data: Withdrawal[];
  onAccept: (id: string) => () => void;
  onReject: (id: string) => () => void;
};

const Table = ({ data, onAccept, onReject }: Props) => {
  const [expandedItemList, setExpandedItemList] = useState<Set<string>>(new Set([]));

  const onDetailsClick = (id: string) => () => {
    if (expandedItemList.has(id))
      setExpandedItemList(new Set([...expandedItemList].filter((i) => i !== id)));
    else setExpandedItemList(new Set([...expandedItemList, id]));
  };

  const elementsMapFunction = (withdrawal: Withdrawal) => (
    <div key={withdrawal.id}>
      <div className="flex">
        <Body size="m" className="px-3 py-4 w-1/6 break-words">
          {withdrawal.id}
        </Body>
        <Body size="m" className="px-3 py-4 w-1/12 break-words">
          {withdrawal.currency.toUpperCase()}
        </Body>
        <Body size="m" className="px-3 py-4 w-1/6 break-words">
          {withdrawal.amount}
        </Body>
        <Body size="m" className="px-3 py-4 w-1/4">
          {format(new Date(withdrawal.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </Body>
        <Body size="m" className="px-3 py-4 w-1/4">
          {withdrawal.confirmedAt
            ? format(new Date(withdrawal.confirmedAt), 'yyyy/MM/dd HH:mm:ss')
            : ''}
        </Body>
        <div className="w-1/12 pt-3 pr-3 flex justify-end">
          <img
            src={IcArrowDown}
            className={classNames('w-6 h-6 transition-all duration-200', {
              'rotate-180': expandedItemList.has(withdrawal.id),
            })}
            onClick={onDetailsClick(withdrawal.id)}
          />
        </div>
      </div>
      <div
        className={classNames('transition-[max-height] overflow-hidden duration-[400ms]', {
          'max-h-0': !expandedItemList.has(withdrawal.id),
          'max-h-80': expandedItemList.has(withdrawal.id),
        })}
      >
        <div className="pl-14 py-4">
          <div className="mb-6">
            <H6 className="text-grey-700">Transaction Hash</H6>
            <Body size="m">{withdrawal.transaction}</Body>
          </div>
          <div className="mb-6 flex gap-3">
            <Button size="s" onClick={onAccept(withdrawal.id)}>
              放行提領
            </Button>
            <Button size="s" appearance="secondary" onClick={onReject(withdrawal.id)}>
              拒絕提領
            </Button>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );

  return (
    <>
      <div className="flex">
        <Subtitle className="px-3 py-4 w-1/6">ID</Subtitle>
        <Subtitle className="px-3 py-4 w-1/12">Currency</Subtitle>
        <Subtitle className="px-3 py-4 w-1/6">Amount</Subtitle>
        <Subtitle className="px-3 py-4 w-1/4">Created Time</Subtitle>
        <Subtitle className="px-3 py-4 w-1/4">Confirmed Time</Subtitle>
        <div className="w-1/12" />
      </div>
      <Divider />
      {data.map(elementsMapFunction)}
    </>
  );
};

export default Table;
