import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLHeadingElement>;

const H6 = ({ className, ...props }: Props) => (
  <h6 className={classNames('text-sm leading-6 font-bold', className)} {...props} />
);

export default H6;
