import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import { Serverity } from 'src/constant/Notification';
import { Auth as AuthPage, Dashboard as DashboardPage } from 'src/constant/Page';
import { SignInForm as FormType } from 'src/model/Form';
import { openSnackbar } from 'src/redux/notificationSlice';
import { login } from 'src/service/authService';

const SignInForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const methods = useForm<FormType>();

  const onSubmit = (data: FormType) => {
    login(data)
      .then(() => {
        navigate(DashboardPage.Root + DashboardPage.Balance);
      })
      .catch((error) =>
        dispatch(
          openSnackbar({
            serverity: Serverity.Error,
            message: t(error),
          }),
        ),
      );
  };

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <div className="mt-4 pt-4 pb-2">
        <FormInput name="account" label={t('auth.signIn.account')} type="text" required autoFocus />
      </div>
      <div className="pt-4 pb-2">
        <FormInput name="password" label={t('auth.signIn.password')} type="password" required />
      </div>
      <div className="mt-6 mb-10">
        <Button
          type="button"
          appearance="text"
          onClick={() => navigate(AuthPage.Root + AuthPage.ForgotPassword)}
        >
          {t('auth.signIn.forgotPassword')}
        </Button>
      </div>
      <div className="text-right">
        <Button type="submit">{t('auth.signIn.signInAct')}</Button>
      </div>
    </Form>
  );
};

export default SignInForm;
