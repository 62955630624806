import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLHeadingElement>;

const H3 = ({ className, ...props }: Props) => (
  <h3 className={classNames('text-2xl leading-[1.4] font-bold', className)} {...props} />
);

export default H3;
