import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Layout from 'src/component/LayoutDashboard';
import Body from 'src/component/typography/Body';
import H2 from 'src/component/typography/H2';
import H4 from 'src/component/typography/H4';
import H6 from 'src/component/typography/H6';
import { Dashboard } from 'src/constant/Page';
import { Balance as BalanceType } from 'src/model/Balance';
import { getBalances } from 'src/service/balanceService';

const Balance = () => {
  const navigate = useNavigate();
  const [balances, setBalances] = useState<BalanceType[]>();

  useEffect(() => {
    getBalances().then((res) => setBalances(res));
  }, []);

  return (
    <Layout>
      <H2 className="mb-4">Balance</H2>
      {balances?.map((balance) => (
        <div key={balance.id}>
          <div className="pt-6 sm:flex sm:items-center sm:p-0">
            <H4 className="sm:w-1/4">{balance.currency.toUpperCase()}</H4>
            <div className="flex flex-col xs:flex-row sm:flex-1">
              <div className="flex sm:w-2/3 flex-1">
                <div className="p-3 w-1/2 sm:p-6">
                  <Body size="s" className="text-grey-500">
                    Total Balance
                  </Body>
                  <H6 className="text-grey-700">{balance.balance}</H6>
                </div>
                <div className="p-3 w-1/2 sm:p-6">
                  <Body size="s" className="text-grey-500">
                    Free Balance
                  </Body>
                  <H6 className="text-grey-700">{balance.freeBalance}</H6>
                </div>
              </div>
              <div className="flex xs:self-center justify-end mt-2 mb-4 xs:my-0 xs:mr-3 xs:w-1/3">
                <Button
                  appearance="border"
                  size="s"
                  onClick={() =>
                    navigate(Dashboard.Root + Dashboard.Withdraw, {
                      state: { currency: balance.currency },
                    })
                  }
                >
                  Withdraw
                </Button>
              </div>
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </Layout>
  );
};

export default Balance;
