import { useState } from 'react';
import Button from 'src/component/Button';
import Layout from 'src/component/LayoutDashboard';
import H2 from 'src/component/typography/H2';
import H4 from 'src/component/typography/H4';
import ParameterModal from './ParameterModal';
import PasswordModal from './PasswordModal';

const Settings = () => {
  const [pwdModalOpen, setPwdModalOpen] = useState<boolean>(false);
  const [paramModalOpen, setParamModalOpen] = useState<boolean>(false);

  return (
    <>
      <Layout>
        <H2 className="mb-4">Settings</H2>
        <div className="mb-6 bg-white p-4 sm:p-6 rounded-lg flex gap-3 items-center justify-between">
          <H4>Sign-in Password</H4>
          <Button appearance="border" size="s" onClick={() => setPwdModalOpen(true)}>
            Edit
          </Button>
        </div>
        <div className="bg-white p-4 sm:p-6 rounded-lg flex gap-3 items-center justify-between">
          <H4>Parameters Settings</H4>
          <Button appearance="border" size="s" onClick={() => setParamModalOpen(true)}>
            Edit
          </Button>
        </div>
      </Layout>
      <PasswordModal open={pwdModalOpen} handleClose={() => setPwdModalOpen(false)} />
      <ParameterModal open={paramModalOpen} handleClose={() => setParamModalOpen(false)} />
    </>
  );
};

export default Settings;
