import { AxiosError } from 'axios';
import { setLoggedIn } from 'src/redux/authSlice';
import { dispatch } from 'src/redux/store';

type SeleneError = {
  status: number;
  name: string;
  message: string;
  code: string;
};

export const handleError = (e: unknown): SeleneError => {
  const seleneError = (e as AxiosError<SeleneError>).response?.data;
  if (seleneError?.status === 401 && seleneError?.name === 'BadTokenError')
    throw 'error.hasBeenLoggedOut';

  return (
    seleneError ?? {
      status: 500,
      name: 'InternalServerError',
      message: 'Internal Server Error',
      code: 'E:500:0',
    }
  );
};

export const handleBadTokenError = (e: unknown) => {
  const seleneError = (e as AxiosError<SeleneError>).response?.data;
  if (seleneError?.status === 401 && seleneError.name === 'BadTokenError') {
    sessionStorage.removeItem('access-token');
    sessionStorage.removeItem('access-secret');
    dispatch(setLoggedIn(false));
  }
};
