import { useTranslation } from 'react-i18next';
import Layout from 'src/component/LayoutWelcome';
import Body from 'src/component/typography/Body';
import H1 from 'src/component/typography/H1';
import H4 from 'src/component/typography/H4';
import IcAirPlane from 'src/image/welcome/ic-airplane.svg';
import IcMailBox from 'src/image/welcome/ic-mail-box.svg';
import IcMobileInfo from 'src/image/welcome/ic-mobile-info.svg';

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <div className="xs:flex xs:justify-between xs:items-center">
          <H1 className="pt-10 xs:pt-0 mx-4 mb-5 xs:m-0">{t('welcome:contactUs.heading')}</H1>
          <img src={IcMobileInfo} />
        </div>
      </Layout>
      <Layout className="bg-white">
        <div className="py-10 flex flex-col xs:flex-row">
          <div className="p-4 max-w-[464px] flex-1">
            <div className="cursor-pointer shadow-md rounded-lg p-4">
              <div className="flex items-center mb-4">
                <img src={IcMailBox} className="mr-4" />
                <H4>Email</H4>
              </div>
              <Body>support@bisa.io</Body>
            </div>
          </div>
          <div className="p-4 max-w-[464px] flex-1">
            <div className="cursor-pointer shadow-md rounded-lg p-4 max-w-[434px]">
              <div className="flex items-center mb-4">
                <img src={IcAirPlane} className="mr-4" />
                <H4>Telegram</H4>
              </div>
              <Body>@bisabisabisa</Body>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ContactUs;
