import { Autocomplete as MuiAutocomplete } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';
import IcClear from 'src/image/ic-clear.svg';
import IcDropdownArrowDown from 'src/image/ic-dropdown-arrow-down.svg';
import IcDropdownArrowUp from 'src/image/ic-dropdown-arrow-up.svg';
import IcDropdownDisable from 'src/image/ic-dropdown-disable.svg';
import Body from './typography/Body';

type AutocompleteProps = {
  label?: string;
  options: string[];
  setValue?: (i: string | null) => void;
  value?: string | null;
  disabled?: boolean;
  help?: string;
  noOptionsText?: string;
  className?: string;
  placeholder?: string;
  asterisk?: boolean;
};

const Autocomplete = ({
  label,
  disabled = false,
  help,
  options,
  setValue,
  value,
  noOptionsText,
  className,
  placeholder,
  asterisk,
}: AutocompleteProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onItemClick = (v: string | null) => {
    setValue && setValue(v);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={className}>
      {label !== undefined && (
        <Body
          size="m"
          className={classNames('mb-[5px]', {
            'text-grey-300': disabled,
          })}
        >
          {label}
          {asterisk && <span className="text-alert-500">*</span>}
        </Body>
      )}
      <MuiAutocomplete
        openOnFocus
        disabled={disabled}
        options={options}
        onChange={(_event, newValue) => {
          onItemClick(newValue);
        }}
        onClose={handleClose}
        onOpen={onOpen}
        classes={{
          paper: 'box-border !bg-white !rounded-lg !shadow my-1 py-2',
          option:
            'cursor-pointer box-border h-10 px-2 hover:bg-light-100 aria-selected:text-primary-500',
          listbox: '!p-0',
        }}
        noOptionsText={noOptionsText}
        value={value}
        renderInput={(params) => (
          <div
            ref={params.InputProps.ref}
            className={classNames(
              'w-full h-10 rounded-lg px-2 flex items-center bg-light-200 border-solid border-[1px] border-light-200',
              {
                'bg-light-100 text-grey-300': disabled !== false,
                'outline-none border-solid border-[1px] border-primary-500': isOpen === true,
              },
            )}
          >
            <input
              disabled={disabled}
              placeholder={placeholder}
              {...params.inputProps}
              className="w-full border-0 bg-transparent outline-none"
            />
            <div className="h-6 w-6">
              {value && <img className="h-full" src={IcClear} onClick={() => onItemClick(null)} />}
            </div>
            <img
              className="h-6 w-6"
              src={disabled ? IcDropdownDisable : isOpen ? IcDropdownArrowUp : IcDropdownArrowDown}
            />
          </div>
        )}
      />
      {help && (
        <div className="mt-[5px]">
          <Body size="s" className="text-grey-700">
            {help}
          </Body>
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
