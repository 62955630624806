import classNames from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import IcBackwardDark from 'src/image/dashboard/ic-backward-dark.svg';
import IcBackwardLight from 'src/image/dashboard/ic-backward-light.svg';
import IcForwardDark from 'src/image/dashboard/ic-forward-dark.svg';
import IcForwardLight from 'src/image/dashboard/ic-forward-light.svg';
import { PageForm } from 'src/model/Form';
import Form from './Form';
import FormInput from './FormInput';
import Body from './typography/Body';

type Props = {
  page: number;
  pageCount: number;
  setPage?: (v: number) => void;
  className?: string;
};

const Pagination = ({ page, pageCount, setPage, className }: Props) => {
  const methods = useForm<PageForm>();

  useEffect(() => {
    methods.setValue('page', page.toString());
  }, [page]);

  const isValidPage = (v: number) => v >= 1 && v <= pageCount;

  const onPageChange = (v: number) => {
    if (!isValidPage(v)) return;
    setPage && setPage(v);
  };

  const onSubmit = (data: PageForm) => {
    const inputPage = Number(data.page);
    if (isValidPage(inputPage)) setPage && setPage(inputPage);
  };

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      className={classNames('flex items-center h-10', className)}
    >
      <img
        src={page <= 1 ? IcBackwardLight : IcBackwardDark}
        className="cursor-pointer"
        onClick={() => onPageChange(page - 1)}
      />
      <FormInput
        name="page"
        type="number"
        required
        className="w-10 m-0"
        regex={new RegExp('^\\d*$')}
      />
      <Body className="p-2 text-grey-700">/</Body>
      <Body className="p-2 text-grey-700">{pageCount}</Body>
      <img
        src={page >= pageCount ? IcForwardLight : IcForwardDark}
        className="cursor-pointer"
        onClick={() => onPageChange(page + 1)}
      />
    </Form>
  );
};

export default Pagination;
