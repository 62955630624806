import authEndpoint from 'src/api/authEndpoint';
import { RegisterFormData, SignInForm } from 'src/model/Form';
import { setLoggedIn } from 'src/redux/authSlice';
import { dispatch } from 'src/redux/store';
import { handleError } from 'src/util/errorHandler';

export const register = async (data: any) => {
  try {
    // await http.post<PostRegisterResponse, PostRegisterRequest>('auth/register', data);
  } catch (e) {
    const error = handleError(e);
    let message = 'error.unexpectedError';
    switch (error.status) {
      case 409: {
        if (error.name === 'ConflictResourceError') message = 'error.emailConflict';
        break;
      }
    }
    throw message;
  }
};

export const login = async (data: SignInForm) => {
  const res = await authEndpoint.login(data.account, data.password);
  sessionStorage.setItem('account', data.account);
  sessionStorage.setItem('token', res.data.token);
  sessionStorage.setItem('key', res.data.key);
  dispatch(setLoggedIn(true));
};

export const logout = async () => {
  sessionStorage.removeItem('account');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('key');
  dispatch(setLoggedIn(false));
};

export const validateRegisterData = (
  data: RegisterFormData,
): { name: keyof RegisterFormData; message: string } | null => {
  let res: { name: keyof RegisterFormData; message: string } | null = null;
  if (data.password !== data.confirmPassword)
    res = { name: 'confirmPassword', message: 'dashboard:auth.register.desc.isNotTheSame' };

  return res;
};

export const getIsLoggedIn = () =>
  (sessionStorage.getItem('account')?.length ?? 0) > 0 &&
  (sessionStorage.getItem('token')?.length ?? 0) > 0 &&
  (sessionStorage.getItem('key')?.length ?? 0) > 0;
