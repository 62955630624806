import { GetPaymentResponse } from 'src/model/Api';
import { handleError } from 'src/util/errorHandler';
import http from 'src/util/http';

const getPayment = async (currency: string, address: string) => {
  try {
    return await http.get<GetPaymentResponse>(`payment/${currency}/${address}`);
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

export default {
  getPayment,
};
