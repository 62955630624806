import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import IcLogo from 'src/image/ic-logo-bisa.svg';

type Props = HTMLAttributes<HTMLHeadingElement>;

const Navbar = ({ className, ...props }: Props) => (
  <div className={classNames(className, 'flex items-center px-[15px] xs:px-[60px]')} {...props}>
    <img src={IcLogo} />
  </div>
);

export default Navbar;
