import { useTranslation } from 'react-i18next';
import H2 from 'src/component/typography/H2';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <div className="mx-4 mt-10 xs:mx-auto xs:w-[560px]">
      <H2>{t('auth.forgotPassword.title')}</H2>
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPassword;
