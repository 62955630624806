import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Autocomplete from 'src/component/Autocomplete';
import Button from 'src/component/Button';
import Divider from 'src/component/Divider';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import H3 from 'src/component/typography/H3';
import { Serverity } from 'src/constant/Notification';
import { Currency } from 'src/model/Currency';
import { ParameterForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/notificationSlice';
import { getCurrencies, getParameterOfCurrency, saveParameter } from 'src/service/settingsService';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const ParameterModal = ({ open, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currencies, setCurrencies] = useState<Currency[]>();
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);
  const methods = useForm<ParameterForm>();

  useEffect(() => {
    getCurrencies()
      .then((res) => setCurrencies(res))
      .catch((error) =>
        dispatch(
          openSnackbar({
            serverity: Serverity.Error,
            message: t(error),
          }),
        ),
      );
  }, []);

  useEffect(() => {
    if (selectedCurrency === null) return;
    getParameterOfCurrency(selectedCurrency.toLowerCase()).then((res) => {
      methods.setValue('freezeLow', res.freezeLow ?? '');
      methods.setValue('freezeHigh', res.freezeHigh ?? '');
      methods.setValue('payoutAmount', res.payoutAmount ?? '');
      methods.setValue('withdrawalReviewAmount', res.withdrawalReviewAmount ?? '');
    });
  }, [selectedCurrency]);

  const onClose = () => {
    setSelectedCurrency(null);
    handleClose();
  };

  const onSubmit = (data: ParameterForm) => {
    if (selectedCurrency === null) return;
    saveParameter(selectedCurrency.toLowerCase(), data);
  };

  return (
    <Modal open={open} handleClose={onClose}>
      <>
        <H3 className="mb-6">Parameters Settings</H3>
        <Autocomplete
          options={(currencies ?? []).map((currency: Currency) => currency.id.toUpperCase())}
          label="Currency"
          className="w-1/3 sm:w-auto"
          setValue={setSelectedCurrency}
          value={selectedCurrency}
        />
        <Divider className="my-6" />
        <Form methods={methods} onSubmit={onSubmit}>
          <div className="flex flex-col xs:flex-row gap-0 xs:gap-10">
            <div className="pt-4 pb-2 xs:w-1/2">
              <FormInput name="freezeLow" label="Freeze Low" disabled={selectedCurrency === null} />
            </div>
            <div className="pt-4 pb-2 xs:w-1/2">
              <FormInput
                name="freezeHigh"
                label="Freeze High"
                disabled={selectedCurrency === null}
              />
            </div>
          </div>
          <div className="pt-4 pb-2">
            <FormInput
              name="payoutAmount"
              label="Payout Amount"
              disabled={selectedCurrency === null}
            />
          </div>
          <div className="pt-4 pb-2">
            <FormInput
              name="withdrawalReviewAmount"
              label="Withdrawal Review Amount"
              disabled={selectedCurrency === null}
            />
          </div>
          <div className="mt-6 flex justify-end">
            <Button type="submit" disabled={selectedCurrency === null}>
              Save
            </Button>
          </div>
        </Form>
      </>
    </Modal>
  );
};

export default ParameterModal;
