import classNames from 'classnames';
import { ChangeEvent, forwardRef, InputHTMLAttributes, useState } from 'react';
import Body from './typography/Body';

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  helper?: string;
  error?: boolean | string;
  regex?: RegExp;
  startsWith?: string;
  asterisk?: boolean;
};

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      helper,
      error,
      disabled,
      onChange,
      regex,
      defaultValue,
      className,
      startsWith,
      asterisk,
      ...props
    },
    ref,
  ) => {
    const [value, setValue] = useState<string>((defaultValue as string) ?? '');
    const onInput = (v: ChangeEvent<HTMLInputElement>) => {
      const input = startsWith ? v.target.value.substring(startsWith.length) : v.target.value;
      if (regex !== undefined && regex.test(input) === false) return;
      setValue(input);
      onChange && onChange({ ...v, target: { ...v.target, value: input } });
    };

    return (
      <div>
        {label && (
          <Body
            size="m"
            className={classNames('mb-[5px]', {
              'text-grey-300': disabled,
            })}
          >
            {label}
            {asterisk && <span className="text-alert-500">*</span>}
          </Body>
        )}
        <input
          className={classNames(
            'rounded-lg bg-light-200 outline-none p-2 h-10 w-full border-solid border-[1px] focus:border-primary-500',
            className,
            {
              'border-alert-500': !!error,
              'border-transparent': !error,
              'text-grey-300 placeholder:text-grey-300': !!disabled,
              'placeholder:text-grey-500': !disabled,
            },
          )}
          ref={ref}
          disabled={disabled}
          autoComplete="off"
          value={`${startsWith ?? ''}${value}`}
          onChange={onInput}
          {...props}
        />
        {(typeof error === 'string' || helper) && (
          <div className="mt-[5px]">
            {typeof error === 'string' && (
              <Body size="s" className="text-alert-500">
                {error}
              </Body>
            )}
            {helper && (
              <Body size="s" className="text-grey-700">
                {helper}
              </Body>
            )}
          </div>
        )}
      </div>
    );
  },
);

export default Input;
