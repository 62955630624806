import { GetCurrenciesResponse } from 'src/model/Api';
import { handleError } from 'src/util/errorHandler';
import http from 'src/util/http';

const getSupportedCurrencies = async () => {
  try {
    return await http.get<GetCurrenciesResponse>('currencies');
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

export default {
  getSupportedCurrencies,
};
