import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';

const Welcome = () => (
  <>
    <div className="bg-white">
      <Navbar className="h-[100px]" />
    </div>
    <Outlet />
    <Footer />
  </>
);

export default Welcome;
