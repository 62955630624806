import { useEffect, useMemo, useState } from 'react';

const COUNT_PER_PAGE = 10;

export const usePagination = () => {
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>();
  const [limit, setLimit] = useState<number>(COUNT_PER_PAGE);
  const offset = useMemo(() => (page - 1) * limit, [page]);
  const pageCount = useMemo(() => Math.ceil((count ?? 0) / limit), [count]);

  useEffect(() => {
    setPage(1);
    setCount(0);
  }, [limit]);

  return {
    limit,
    offset,
    setLimit,
    setCount,
    paginationProps: { page, setPage, pageCount },
  };
};
