import { Popover as MuiPopover, PopoverProps as MuiPopoverProps } from '@mui/material';

export type PopoverProps = MuiPopoverProps;

const Popover = ({ onClose, anchorEl, open, children }: PopoverProps) => (
  <MuiPopover
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    PaperProps={{ className: '!rounded-lg' }}
  >
    <div className="py-2">{children}</div>
  </MuiPopover>
);

export default Popover;
