import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import H3 from 'src/component/typography/H3';
import { Serverity } from 'src/constant/Notification';
import { ResetPasswordForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/notificationSlice';
import { resetPassword } from 'src/service/settingsService';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const PasswordModal = ({ open, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm<ResetPasswordForm>();

  const onSubmit = (data: ResetPasswordForm) => {
    if (data.new !== data.confirm) {
      methods.setError('confirm', {});

      return;
    }
    resetPassword(data.old, data.new)
      .then(onClose)
      .catch((error) =>
        dispatch(
          openSnackbar({
            serverity: Serverity.Error,
            message: t(error),
          }),
        ),
      );
  };

  const onClose = () => {
    methods.reset();
    handleClose();
  };

  return (
    <Modal open={open} handleClose={onClose}>
      <Form methods={methods} onSubmit={onSubmit}>
        <H3 className="mb-6">Sign-in Password</H3>
        <div className="pt-4 pb-2">
          <FormInput name="old" label="Old Password" asterisk required type="password" />
        </div>
        <div className="pt-4 pb-2">
          <FormInput name="new" label="New Password" asterisk required type="password" />
        </div>
        <div className="pt-4 pb-2">
          <FormInput
            name="confirm"
            label="Confirm New Password"
            asterisk
            required
            type="password"
          />
        </div>
        <div className="flex justify-end mt-6">
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default PasswordModal;
