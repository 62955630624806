import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Popover, { PopoverProps } from 'src/component/Popover';
import Body from 'src/component/typography/Body';
import { Welcome as Page } from 'src/constant/Page';

type Props = { onClose: () => void } & PopoverProps;

const NavbarMenu = ({ onClose, ...props }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goto = (path: Page) => () => {
    onClose();
    navigate(path);
  };

  return (
    <Popover onClose={onClose} {...props}>
      <Body size="s" className="pt-3 px-4 pb-1 text-grey-500">
        {t('welcome:navbar.forReceivingPayments')}
      </Body>
      <Body className="px-6 py-2 cursor-pointer hover:bg-light-200" onClick={goto(Page.OrderMode)}>
        {t('welcome:pages.orderMode')}
      </Body>
      <Body
        className="px-6 py-2 cursor-pointer hover:bg-light-200"
        onClick={goto(Page.AccountMode)}
      >
        {t('welcome:pages.accountMode')}
      </Body>
      <Body size="s" className="pt-3 px-4 pb-1 text-grey-500">
        {t('welcome:navbar.forWithdrawal')}
      </Body>
      <Body
        className="px-6 py-2 cursor-pointer hover:bg-light-200"
        onClick={goto(Page.WithdrawalProcess)}
      >
        {t('welcome:pages.withdrawalProcess')}
      </Body>
    </Popover>
  );
};

export default NavbarMenu;
