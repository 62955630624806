import withdrawalEndpoint from 'src/api/withdrawalEndpoint';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';
import { loadCurrencies } from './commonService';

export const getCurrencies = async () => await loadCurrencies();

export const createWithdrawal = async (currency: string, amount: string, address: string) => {
  try {
    dispatch(startWaiting());
    const account = sessionStorage.getItem('account') ?? '';
    await withdrawalEndpoint.postWithdrawals(account, currency, {
      amount,
      address,
      clientId: Date.now().toString(),
      isFullPayment: true,
      timestamp: Date.now(),
    });
  } finally {
    dispatch(finishWaiting());
  }
};
