import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Parameter } from 'src/model/Balance';

export type ParameterState = {
  parameters: { [key: string]: Parameter };
};

const initialState: ParameterState = {
  parameters: {},
};

export const parameterSlice = createSlice({
  name: 'parameter',
  initialState,
  reducers: {
    updateParametersOfCurrency: (
      state: ParameterState,
      action: PayloadAction<{ currency: string; parameter: Parameter }>,
    ) => {
      state.parameters[action.payload.currency] = action.payload.parameter;
    },
    updateParametersOfAll: (
      state: ParameterState,
      action: PayloadAction<{ [key: string]: Parameter }>,
    ) => {
      state.parameters = action.payload;
    },
  },
});

export const { updateParametersOfCurrency, updateParametersOfAll } = parameterSlice.actions;
export default parameterSlice.reducer;
