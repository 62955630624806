import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLHeadingElement>;

const H4 = ({ className, ...props }: Props) => (
  <h4 className={classNames('text-xl leading-6 font-bold', className)} {...props} />
);

export default H4;
