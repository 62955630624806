import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Body from 'src/component/typography/Body';
import H4 from 'src/component/typography/H4';
import H5 from 'src/component/typography/H5';
import { Serverity } from 'src/constant/Notification';
import { Welcome } from 'src/constant/Page';
import useQuery from 'src/hook/useQuery';
import IcCopy from 'src/image/ic-copy.svg';
import { Payment as Type } from 'src/model/Payment';
import { openSnackbar } from 'src/redux/notificationSlice';
import { getPayment } from 'src/service/paymentService';

const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currency, address } = useQuery();
  const [payment, setPayment] = useState<Type>();

  useEffect(() => {
    if (currency && address)
      getPayment(currency, address)
        .then((res) => setPayment(res))
        .catch(() => navigate(Welcome.Root, { replace: true }));
  }, [currency, address]);

  const onCopy = () => {
    dispatch(openSnackbar({ message: 'Copy successfully', serverity: Serverity.Success }));
  };

  return (
    <div className="mt-10 mb-[60px] mx-4 p-4 xs:mx-10 xs:p-6 sm:w-[920px] sm:mx-auto bg-white rounded-[10px]">
      <div className="flex flex-col gap-4 xs:flex-row xs:gap-10 xs:mb-6">
        <div className="self-center mt-4 xs:mt-0 w-[200px] p-3">
          {payment ? (
            <QRCode value={payment?.address} size={176} />
          ) : (
            <div className="w-[176px] h-[176px]" />
          )}
        </div>
        <div>
          <H4 className="p-4 text-center xs:text-left">{payment?.account.name}</H4>
          <div className="py-2 px-4">
            <Body size="m" className="text-grey-700">
              Currency
            </Body>
            <H5 className="text-black-500 mb-4">{payment?.currency.name}</H5>
          </div>
        </div>
      </div>
      <div className="pt-4 pb-2 px-4">
        <Body size="m" className="text-grey-700">
          Address
        </Body>
        <div className="flex gap-4 items-start">
          <H5 className="text-black-500 mb-4 break-all">{address}</H5>
          <CopyToClipboard text={address} onCopy={onCopy}>
            <img src={IcCopy} className="w-[21px] cursor-pointer" />
          </CopyToClipboard>
        </div>
      </div>
      <div className="pt-4 pb-2 px-4">
        <Body size="m" className="text-grey-700">
          Payment ID
        </Body>
        <H5 className="text-black-500 mb-4">{payment?.id}</H5>
      </div>
    </div>
  );
};

export default Payment;
