import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement> & { focus?: boolean };

const ListItem = ({ children, focus, className, ...props }: Props) => (
  <div
    className={classNames(
      'h-10 p-2 bg-white cursor-pointer hover:bg-light-200 hover:bg-opacity-50',
      className,
      {
        ['text-primary-500']: focus,
      },
    )}
    {...props}
  >
    {children}
  </div>
);

export default ListItem;
