import { endOfDay, startOfDay } from 'date-fns';
import { useState } from 'react';
import { DateRange } from 'src/model/DateFilter';

export const DEFAULT_RANGE = 90 * 24 * 60 * 60 * 1000; // 90 days

export const useDateFilter = () => {
  const [toDate, setToDate] = useState<Date>(endOfDay(new Date()));
  const [fromDate, setFromDate] = useState<Date>(startOfDay(new Date().getTime() - DEFAULT_RANGE));

  const setDateRange = (dateRange: DateRange) => {
    dateRange.from && setFromDate(startOfDay(dateRange.from));
    dateRange.to && setToDate(endOfDay(dateRange.to));
  };

  return {
    begin: fromDate.toISOString(),
    end: toDate.toISOString(),
    dateFilterProps: {
      fromDate,
      toDate,
      setDateRange,
    },
  };
};
