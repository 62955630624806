import { GetAddressesParams, GetAddressesResponse } from 'src/model/Api';
import { handleError } from 'src/util/errorHandler';
import http from 'src/util/http';

const getAddresses = async (account: string, currency: string, params?: GetAddressesParams) => {
  try {
    return await http.authGet<GetAddressesResponse>(`${account}/${currency}/addresses`, { params });
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

export default {
  getAddresses,
};
