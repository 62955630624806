import { configureStore, PayloadAction, Store } from '@reduxjs/toolkit';
import authReducer, { AuthState } from './authSlice';
import notificationReducer, { NotificationState } from './notificationSlice';
import parameterReducer, { ParameterState } from './parameterSlice';
import publicReducer, { PublicState } from './publicSlice';
import uiReducer, { UiState } from './uiSlice';

export type RootState = {
  auth: AuthState;
  notification: NotificationState;
  parameter: ParameterState;
  public: PublicState;
  ui: UiState;
};

let store: Store<RootState>;

export const configStore = () => {
  store = configureStore({
    reducer: {
      auth: authReducer,
      notification: notificationReducer,
      parameter: parameterReducer,
      public: publicReducer,
      ui: uiReducer,
    },
  });

  return store;
};

export const getState = () => store.getState();

export const dispatch = <T>(action: PayloadAction<T>) => store.dispatch(action);
