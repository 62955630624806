import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Drawer, { DrawerProps } from 'src/component/Drawer';
import LanguageMenu from 'src/component/LanguageMenu';
import Body from 'src/component/typography/Body';
import { Welcome as Page } from 'src/constant/Page';
import IcClose from 'src/image/ic-close.svg';
import IcDown from 'src/image/ic-down-s.svg';

type NavbarDrawerProps = DrawerProps & {
  onClose: () => void;
};

const NavbarDrawer = ({ open, onClose }: NavbarDrawerProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const isLangMenuOpen = Boolean(langMenuAnchorEl);

  const goto = (path: Page) => () => {
    onClose();
    navigate(path);
  };

  const handleLangMenuClick = (event: MouseEvent<HTMLDivElement>) => {
    if (isLangMenuOpen) setLangMenuAnchorEl(null);
    else setLangMenuAnchorEl(event.currentTarget);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <img className="ml-auto w-[30px] h-[30px]" onClick={onClose} src={IcClose} />
      <Body bold size="m" className="p-2">
        {t('welcome:navbar.howItWorks')}
      </Body>
      <Body size="s" className="text-grey-500 pt-2 px-4 pb-1">
        {t('welcome:navbar.forReceivingPayments')}
      </Body>
      <Body size="m" className="px-4 py-[10px] active:bg-light-200" onClick={goto(Page.OrderMode)}>
        {t('welcome:pages.orderMode')}
      </Body>
      <Body
        size="m"
        className="px-4 py-[10px] active:bg-light-200"
        onClick={goto(Page.AccountMode)}
      >
        {t('welcome:pages.accountMode')}
      </Body>
      <Body size="s" className="text-grey-500 pt-2 px-4 pb-1">
        {t('welcome:navbar.forWithdrawal')}
      </Body>
      <Body
        size="m"
        className="px-4 py-[10px] active:bg-light-200"
        onClick={goto(Page.WithdrawalProcess)}
      >
        {t('welcome:pages.withdrawalProcess')}
      </Body>
      <Body size="m" bold className="mt-4 px-2 py-4">
        {t('welcome:pages.supportedCoins')}
      </Body>
      <div className="flex px-2 py-4">
        <Body size="m" bold onClick={handleLangMenuClick}>
          {t('welcome:language')}
        </Body>
        <img src={IcDown} />
      </div>
      <LanguageMenu
        open={isLangMenuOpen}
        anchorEl={langMenuAnchorEl}
        onClose={() => setLangMenuAnchorEl(null)}
      />
    </Drawer>
  );
};

export default NavbarDrawer;
