import classNames from 'classnames';
import { forwardRef, InputHTMLAttributes } from 'react';
import style from './Input.module.scss';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  error?: boolean;
  disabled?: boolean | undefined;
};

const Input = forwardRef<HTMLInputElement, Props>(
  ({ className, error = false, disabled = false, ...props }, ref) => (
    <input
      ref={ref}
      className={classNames(className, style.input, {
        [style.error]: error,
        [style.disabled]: disabled,
      })}
      {...props}
    />
  ),
);

Input.displayName = 'Input';

export default Input;
