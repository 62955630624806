import { PostLoginRequest, PostLoginResponse, PostResetPasswordRequest } from 'src/model/Api';
import { handleError } from 'src/util/errorHandler';
import http from 'src/util/http';

const login = async (account: string, password: string) => {
  try {
    return await http.post<PostLoginResponse, PostLoginRequest>(`${account}/auth/login`, {
      data: { password },
    });
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

const resetPassword = async (account: string, data: PostResetPasswordRequest) => {
  try {
    return await http.authPatch<void, PostResetPasswordRequest>(`${account}/auth/password`, {
      data,
    });
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

export default {
  login,
  resetPassword,
};
