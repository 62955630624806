import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Body from 'src/component/typography/Body';
import { ForgotPasswordForm as FormType } from 'src/model/Form';

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const methods = useForm<FormType>();

  const onSubmit = () => {
    //
  };

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <Body className="pt-4">{t('auth.forgotPassword.desc')}</Body>
      <div className="pt-4 pb-2">
        <FormInput name="email" label={t('auth.forgotPassword.email')} type="password" required />
      </div>
      <div className="text-right mt-10">
        <Button type="submit">{t('auth.forgotPassword.forgotPasswordAct')}</Button>
      </div>
    </Form>
  );
};

export default ForgotPasswordForm;
