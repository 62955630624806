import depositEndpoint from 'src/api/depositEndpoint';
import { GetDepositsParams } from 'src/model/Api';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';
import { loadCurrencies } from './commonService';

export const getCurrencies = async () => await loadCurrencies();

export const getDepositsOfCurrency = async (currency: string, params?: GetDepositsParams) => {
  try {
    dispatch(startWaiting());
    const account = sessionStorage.getItem('account') ?? '';
    const res = await depositEndpoint.getDeposits(account, currency, params);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};
