import paymentEndpoint from 'src/api/paymentEndpoint';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';

export const getPayment = async (currency: string, address: string) => {
  try {
    dispatch(startWaiting());
    const res = await paymentEndpoint.getPayment(currency, address);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};
