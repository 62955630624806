import { useTranslation } from 'react-i18next';
import Layout from 'src/component/LayoutWelcome';
import Body from 'src/component/typography/Body';
import H1 from 'src/component/typography/H1';
import H4 from 'src/component/typography/H4';
import H5 from 'src/component/typography/H5';
import IcListDot from 'src/image/ic-list-dot.svg';
import IcUsdtTrc20 from 'src/image/welcome/ic-coin-usdt-trc-20.svg';
import IcUsdt from 'src/image/welcome/ic-coin-usdt.svg';
import IcFee from 'src/image/welcome/ic-fee.svg';

const Fees = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout className="mb-[70px]">
        <div className="xs:flex xs:justify-between xs:items-center">
          <H1 className="pt-10 xs:pt-0 mx-4 mb-5 xs:m-0">{t('welcome:feePricing.heading')}</H1>
          <img src={IcFee} className="w-full xs:w-auto" />
        </div>
      </Layout>
      <Layout className="bg-white">
        <H4 className="py-4 border-solid border-b-[1px] border-b-light-300">
          {t('welcome:feePricing.desc.depositFees')}
        </H4>
        <div className="flex border-solid border-b-[1px] border-b-light-300">
          <div className="px-3 py-4 flex gap-1 w-1/3 items-center">
            <img src={IcUsdt} className="w-6" />
            <H5>USDT (ERC20)</H5>
          </div>
          <Body className="px-3 py-4 w-2/3">2 USDT + 0.8%</Body>
        </div>
        <div className="flex border-solid border-b-[1px] border-b-light-300">
          <div className="px-3 py-4 flex gap-1 w-1/3 items-center">
            <img src={IcUsdtTrc20} className="w-6" />
            <H5>USDT (TRC20)</H5>
          </div>
          <Body className="px-3 py-4 w-2/3">1 USDT + 0.4%</Body>
        </div>
        <H4 className="pt-[70px] py-4 border-solid border-b-[1px] border-b-light-300">
          {t('welcome:feePricing.desc.withdrawalFees')}
        </H4>
        <div className="flex border-solid border-b-[1px] border-b-light-300">
          <div className="px-3 py-4 flex gap-1 w-1/3 items-center">
            <img src={IcUsdt} className="w-6" />
            <H5>USDT (ERC20)</H5>
          </div>
          <div className="px-3 py-4 w-2/3">
            <Body>{t('welcome:feePricing.desc.floatingRate')}</Body>
            <Body size="m">{t('welcome:feePricing.desc.erc20RateDesc')}</Body>
          </div>
        </div>
        <div className="flex border-solid border-b-[1px] border-b-light-300">
          <div className="px-3 py-4 flex gap-1 w-1/3 items-center">
            <img src={IcUsdtTrc20} className="w-6" />
            <H5>USDT (TRC20)</H5>
          </div>
          <div className="px-3 py-4 w-2/3">
            <Body>{t('welcome:feePricing.desc.floatingRate')}</Body>
            <Body size="m">{t('welcome:feePricing.desc.trc20RateDesc')}</Body>
          </div>
        </div>
        <H5 className="text-grey-700 mt-10">{t('welcome:feePricing.desc.minimumOrderAmount')}</H5>
        <div className="flex">
          <img src={IcListDot} className="px-2" />
          <Body size="m" className="text-grey-700">
            USDT (ERC20) : 20 USDT
          </Body>
        </div>
        <div className="flex">
          <img src={IcListDot} className="px-2" />
          <Body size="m" className="text-grey-700">
            USDT (TRC20) : 10 USDT
          </Body>
        </div>
        <H5 className="text-grey-700 mt-10">
          {t('welcome:feePricing.desc.minimumWithdrawalAmount')}
        </H5>
        <div className="flex">
          <img src={IcListDot} className="px-2" />
          <Body size="m" className="text-grey-700">
            USDT (ERC20) : 300 USDT
          </Body>
        </div>
        <div className="flex">
          <img src={IcListDot} className="px-2 mb-20" />
          <Body size="m" className="text-grey-700">
            USDT (TRC20) : 150 USDT
          </Body>
        </div>
      </Layout>
    </>
  );
};

export default Fees;
