import classNames from 'classnames';
import { HTMLAttributes, MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import LanguageMenu from 'src/component/LanguageMenu';
import Body from 'src/component/typography/Body';
import { MediaQuery } from 'src/constant/Media';
import { Dashboard as DashboardPage } from 'src/constant/Page';
import IcDown from 'src/image/ic-down-s.svg';
import IcLogo from 'src/image/ic-logo-bisa.svg';
import IcMenu from 'src/image/ic-menu.svg';
import NavbarDrawer from './NavbarDrawer';
import NavbarMenu from './NavbarMenu';

type Props = HTMLAttributes<HTMLHeadingElement>;

const Navbar = ({ className, ...props }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const biggerThanXsm = useMediaPredicate(MediaQuery.Xsm);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const isLangMenuOpen = Boolean(langMenuAnchorEl);
  const ref = useRef(null);

  const gotoDashboard = () => {
    navigate(DashboardPage.Root + DashboardPage.Balance);
  };

  const handleLangMenuClick = (event: MouseEvent<HTMLDivElement>) => {
    if (isLangMenuOpen) setLangMenuAnchorEl(null);
    else setLangMenuAnchorEl(event.currentTarget);
  };

  if (biggerThanXsm)
    return (
      <>
        <div
          className={classNames(
            className,
            'flex xs:mx-10 sm:mx-auto sm:w-[960px] xl:w-[1200px] justify-between items-center',
          )}
          {...props}
        >
          <img src={IcLogo} />
          <div className="flex items-center gap-6">
            <div className="flex" onClick={() => setIsMenuOpen(true)} ref={ref}>
              <Body bold size="m" className="cursor-pointer">
                {t('welcome:navbar.howItWorks')}
              </Body>
              <img src={IcDown} />
            </div>
            <div className="flex">
              <Body bold size="m" className="cursor-pointer">
                {t('welcome:pages.supportedCoins')}
              </Body>
              <img src={IcDown} />
            </div>
            <Button appearance="primary" size="s" onClick={gotoDashboard}>
              {t('welcome:navbar.dashboard')}
            </Button>
            <div className="flex cursor-pointer" onClick={handleLangMenuClick}>
              <Body size="m">{t('welcome:language')}</Body>
              <img src={IcDown} />
            </div>
          </div>
        </div>
        <NavbarMenu open={isMenuOpen} onClose={() => setIsMenuOpen(false)} anchorEl={ref.current} />
        <LanguageMenu
          open={isLangMenuOpen}
          anchorEl={langMenuAnchorEl}
          onClose={() => setLangMenuAnchorEl(null)}
        />
      </>
    );

  return (
    <>
      <div className={classNames(className, 'flex mx-4 justify-between items-center')} {...props}>
        <img src={IcLogo} />
        <div className="flex items-center gap-6">
          <Button appearance="primary" size="s" onClick={gotoDashboard}>
            {t('welcome:navbar.dashboard')}
          </Button>
          <img src={IcMenu} onClick={() => setIsDrawerOpen(true)} />
        </div>
      </div>
      <NavbarDrawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    </>
  );
};

export default Navbar;
