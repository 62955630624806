import { Fade, Slide } from '@mui/material';
import MuiModal, { ModalProps } from '@mui/material/Modal';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { MediaQuery } from 'src/constant/Media';
import IcClose from 'src/image/ic-close.svg';

type Props = ModalProps & {
  handleClose: () => void;
  disableBackdropClick?: boolean;
};

const Modal = ({ open, handleClose, children, disableBackdropClick = false, ...props }: Props) => {
  const isBiggerThanSm = useMediaPredicate(MediaQuery.Sm);

  const onCloseButtonClick = () => {
    handleClose();
  };

  const onMuiModalClose = (event: object, reason: string) => {
    if (!disableBackdropClick || reason !== 'backdropClick') handleClose();
  };

  const ContentWrapper = forwardRef<HTMLDivElement>((wrapperProps, ref) => (
    <div
      ref={ref}
      className={classNames(
        'bg-white rounded-t-xl w-screen box-border pt-10 xs:pt-12 pb-4 top-[100px] bottom-0 max-h-[calc(100vh-100px)] absolute',
        'sm:pb-10 sm:rounded-xl sm:w-[920px] sm:bottom-auto sm:top-1/2 sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2',
      )}
      {...wrapperProps}
    >
      <img
        className="w-6 h-6 absolute cursor-pointer top-4 right-4 sm:top-6 sm:right-6"
        src={IcClose}
        onClick={onCloseButtonClick}
      />
      <div className="w-full px-4 xs:px-10 h-full sm:max-h-[calc(100vh-188px)] overflow-y-auto">
        {children}
      </div>
    </div>
  ));
  ContentWrapper.displayName = 'ModalContentWrapper';

  return (
    <MuiModal open={open} onClose={onMuiModalClose} closeAfterTransition {...props}>
      {isBiggerThanSm ? (
        <Fade in={open}>
          <ContentWrapper />
        </Fade>
      ) : (
        <Slide direction="up" in={open}>
          <ContentWrapper />
        </Slide>
      )}
    </MuiModal>
  );
};

export default Modal;
