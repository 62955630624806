import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import Layout from 'src/component/LayoutWelcome';
import Body from 'src/component/typography/Body';
import H1 from 'src/component/typography/H1';
import H3 from 'src/component/typography/H3';
import { Dashboard as DashboardPage } from 'src/constant/Page';
import IcHero from 'src/image/welcome/ic-landing-bisa-hero.svg';
import IcFeature1 from 'src/image/welcome/ic-landing-feature-01.svg';
import IcFeature2 from 'src/image/welcome/ic-landing-feature-02.svg';
import IcFeature3 from 'src/image/welcome/ic-landing-feature-03.svg';

const Landing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const gotoDashboard = () => {
    navigate(DashboardPage.Root + DashboardPage.Balance);
  };

  return (
    <>
      <Layout className="pb-10 px-4">
        <div className="pt-10 flex flex-col items-center justify-center sm:flex-row sm:gap-10">
          <div className="text-center sm:text-left sm:w-[440px] md:w-[660px]">
            <H1 className="sm:mt-[46px]">{t('welcome:landing.heading')}</H1>
            <Body className="mt-[30px] mb-10">{t('welcome:landing.desc.headingDesc')}</Body>
            <Button appearance="secondary" size="l" onClick={gotoDashboard}>
              {t('welcome:landing.act.getStarted')}
            </Button>
          </div>
          <div className="mt-10 max-w-[560px] sm:mt-0">
            <img src={IcHero} />
          </div>
        </div>
      </Layout>
      <Layout className="bg-white">
        <div className="my-10 xs:flex xs:justify-center">
          <div className="text-center p-6 xs:w-full">
            <img src={IcFeature1} className="mx-auto mb-5" />
            <H3>{t('welcome:landing.desc.securePrivate')}</H3>
            <Body className="mt-[10px]">{t('welcome:landing.desc.securePrivateDesc')}</Body>
          </div>
          <div className="text-center p-6 xs:w-full">
            <img src={IcFeature2} className="mx-auto mb-5" />
            <H3>{t('welcome:landing.desc.fastEasy')}</H3>
            <Body className="mt-[10px]">{t('welcome:landing.desc.fastEasyDesc')}</Body>
          </div>
          <div className="text-center p-6 xs:w-full">
            <img src={IcFeature3} className="mx-auto mb-5" />
            <H3>{t('welcome:landing.desc.globalPayments')}</H3>
            <Body className="mt-[10px]">{t('welcome:landing.desc.globalPaymentsDesc')}</Body>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Landing;
