import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLHeadingElement>;

const H2 = ({ className, ...props }: Props) => (
  <h2
    className={classNames('text-[28px] leading-[1.2] font-bold sm:text-[42px]', className)}
    {...props}
  />
);

export default H2;
