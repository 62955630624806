import classnames from 'classnames';
import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Body from 'src/component/typography/Body';
import H6 from 'src/component/typography/H6';
import IcArrowDown from 'src/image/dashboard/ic-arrow-down.svg';
import { Deposit } from 'src/model/Deposit';

export type Props = {
  data: Deposit[];
};

const Card = ({ data }: Props) => {
  const { t } = useTranslation();
  const [expandedItemList, setExpandedItemList] = useState<Set<string>>(new Set([]));

  const onDetailsClick = (id: string) => () => {
    if (expandedItemList.has(id))
      setExpandedItemList(new Set([...expandedItemList].filter((i) => i !== id)));
    else setExpandedItemList(new Set([...expandedItemList, id]));
  };

  return (
    <>
      {data.map((deposit: Deposit) => (
        <div key={deposit.id} className="p-6 bg-white rounded-lg">
          <div className="px-3 pb-6">
            <Body size="s" className="text-grey-500">
              {t('depositsHistory.desc.id')}
            </Body>
            <H6 className="text-grey-700">{deposit.id}</H6>
          </div>
          <div className="flex">
            <div className="px-3 pb-6 w-1/2">
              <Body size="s" className="text-grey-500">
                Currency
              </Body>
              <H6 className="text-grey-700">{deposit.currency.toUpperCase()}</H6>
            </div>
            <div className="px-3 pb-6 w-1/2">
              <Body size="s" className="text-grey-500">
                {t('depositsHistory.desc.amount')}
              </Body>
              <H6 className="text-grey-700">{deposit.amount}</H6>
            </div>
          </div>
          <div className="flex">
            <div className="px-3 pb-6 w-1/2">
              <Body size="s" className="text-grey-500">
                Created Time
              </Body>
              <H6 className="text-grey-700">
                {format(new Date(deposit.createdAt), 'yyyy/MM/dd HH:mm:ss')}
              </H6>
            </div>
            <div className="px-3 pb-6 w-1/2">
              <Body size="s" className="text-grey-500">
                {t('depositsHistory.desc.confirmationTime')}
              </Body>
              <H6 className="text-grey-700">
                {format(new Date(deposit.confirmedAt), 'yyyy/MM/dd HH:mm:ss')}
              </H6>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <img
              src={IcArrowDown}
              className={classnames('transition-all duration-200 cursor-pointer', {
                'rotate-180': expandedItemList.has(deposit.id),
              })}
              onClick={onDetailsClick(deposit.id)}
            />
          </div>
          <div
            className={classnames('transition-[max-height] duration-[400ms] overflow-hidden', {
              'max-h-0': !expandedItemList.has(deposit.id),
              'max-h-80': expandedItemList.has(deposit.id),
            })}
          >
            <div className="bg-light-200 p-3 rounded-lg mt-4">
              <div className="mb-3">
                <Body size="s" className="text-grey-500">
                  {t('depositsHistory.desc.transactionHash')}
                </Body>
                <H6 className="text-grey-700">{deposit.transaction}</H6>
              </div>
              <div className="mb-3">
                <Body size="s" className="text-grey-500">
                  Block
                </Body>
                <H6 className="text-grey-700">{deposit.block}</H6>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Card;
