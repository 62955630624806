import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Serverity } from 'src/constant/Notification';
import { Auth as AuthPage, Welcome as WelcomePage } from 'src/constant/Page';
import { openSnackbar } from 'src/redux/notificationSlice';
import { logout } from 'src/service/authService';

const SignOut = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    logout()
      .then(() => {
        navigate(`${AuthPage.Root}${AuthPage.SignIn}`, { replace: true });
      })
      .catch((errorMessageKey: string) => {
        dispatch(
          openSnackbar({
            serverity: Serverity.Error,
            message: t(errorMessageKey),
          }),
        );
        navigate(`${WelcomePage.Root}`);
      });
  }, []);

  return <div>SignOut</div>;
};

export default SignOut;
