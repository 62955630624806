import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  appearance?: 'primary' | 'secondary' | 'border' | 'text';
  size?: 's' | 'm' | 'l';
};

const Button = ({ appearance = 'primary', size = 'm', className, ...props }: Props) => (
  <button
    className={classNames('rounded-lg outline-none font-[500] box-border', className, {
      'bg-primary-500 text-white hover:bg-primary-600 active:bg-primary-700 disabled:bg-primary-500 disabled:bg-opacity-30':
        appearance === 'primary',
      'bg-black-700 text-white hover:bg-black-500 active:bg-grey-700 disabled:bg-grey-300':
        appearance === 'secondary',
      'border-2 border-solid border-black-700 text-black-700 bg-white hover:bg-black-500 hover:border-black-500 hover:text-white active:bg-grey-700 active:border-grey-700 disabled:bg-white disabled:border-black-700 disabled:text-black-700 disabled:opacity-30':
        appearance === 'border',
      'text-base bg-transparent text-primary-500 hover:underline active:text-primary-700 disabled:no-underline disabled:text-primary-500 disabled:opacity-30':
        appearance === 'text',
      'px-10 text-base h-12': size === 'l' && appearance !== 'text',
      'px-[30px] text-sm leading-6 h-10': size === 'm' && appearance !== 'text',
      'px-5 text-[13px] leading-[1.38] h-[30px]': size === 's' && appearance !== 'text',
    })}
    {...props}
  />
);

export default Button;
