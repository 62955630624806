import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

const LayoutWelcome = ({ className, ...props }: Props) => (
  <div className={className}>
    <div
      className="mx-4 xs:mx-10 sm:mx-auto sm:w-[960px] md:w-[1120px] lg:w-[1280px] xl:w-[1400px]"
      {...props}
    />
  </div>
);

export default LayoutWelcome;
