import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Body from 'src/component/typography/Body';
import { Welcome as Page } from 'src/constant/Page';
import Logo from 'src/image/welcome/ic-logo-bisa-w.svg';

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="bg-black-600 pt-10 px-4 py-[30px] xs:px-10 ">
      <div className="sm:flex sm:justify-between sm:w-[910px] sm:mx-auto">
        <img src={Logo} className="mb-[30px] sm:mb-0" />
        <div className="text-right xs:flex xs:gap-10 xs:items-center">
          <Body
            size="m"
            className="text-white cursor-pointer mb-5 xs:mb-0"
            onClick={() => navigate(Page.FeesPricing)}
          >
            {t('welcome:pages.feePricing')}
          </Body>
          <Body size="m" className="text-white cursor-pointer mb-5 xs:mb-0">
            {t('welcome:pages.supportedCoins')}
          </Body>
          <Body size="m" className="text-white cursor-pointer mb-5 xs:mb-0">
            {t('welcome:pages.documents')}
          </Body>
          <Body size="m" className="text-white cursor-pointer mb-5 xs:mb-0">
            {t('welcome:pages.faq')}
          </Body>
          <Body
            size="m"
            className="text-white cursor-pointer"
            onClick={() => navigate(Page.ContactUs)}
          >
            {t('welcome:pages.contactUs')}
          </Body>
        </div>
      </div>
      <Body size="s" className="text-grey-300 text-center mt-10">
        Copyright {format(Date.now(), 'yyyy')} © BISA
      </Body>
    </div>
  );
};

export default Footer;
