import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';

const Auth = () => (
  <>
    <Navbar className="h-[100px]" />
    <Outlet />
  </>
);

export default Auth;
