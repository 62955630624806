import { GetPayoutsParams, GetPayoutsResponse } from 'src/model/Api';
import { handleError } from 'src/util/errorHandler';
import http from 'src/util/http';

const getPayouts = async (account: string, currency: string, params?: GetPayoutsParams) => {
  try {
    return await http.authGet<GetPayoutsResponse>(`${account}/${currency}/payouts`, { params });
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

export default {
  getPayouts,
};
