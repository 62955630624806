import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Serverity } from 'src/constant/Notification';
import { Notification } from 'src/model/Notification';

// define the type of state
export type NotificationState = {
  open: boolean;
  message?: string;
  serverity?: Serverity;
};

// define the initial value of state
const initialState: NotificationState = {
  open: false,
  message: undefined,
  serverity: undefined,
};

// define the actions in "reducers"
export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    openSnackbar: (state: NotificationState, action: PayloadAction<Notification>) => {
      state.message = action.payload.message;
      state.serverity = action.payload.serverity;
      state.open = true;
    },
    dismissSnackbar: (state: NotificationState) => {
      state.open = false;
    },
  },
});

// action creators are generated for each case reducer function
export const { openSnackbar, dismissSnackbar } = notificationSlice.actions;

export default notificationSlice.reducer;
