import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/global/Button';
import FormInput from 'src/component/global/FormInput';
import { Serverity } from 'src/constant/Notification';
import { Auth as AuthPage } from 'src/constant/Page';
import { RegisterFormData } from 'src/model/Form';
import { openSnackbar } from 'src/redux/notificationSlice';
import { register as sendRegister, validateRegisterData } from 'src/service/authService';
import style from './RegisterForm.module.scss';

const RegisterForm = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    setFocus,
  } = useForm<RegisterFormData>();

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  const onSubmit: SubmitHandler<RegisterFormData> = (data) => {
    const validation = validateRegisterData(data);
    if (validation) setError(validation.name, { type: 'validate', message: t(validation.message) });
    else {
      setIsLoading(true);
      sendRegister({
        email: data.email,
        password: data.password,
      })
        .then(() => {
          dispatch(
            openSnackbar({
              serverity: Serverity.Success,
              message: t('auth.register.desc.success'),
            }),
          );
          navigate(`${AuthPage.Root}${AuthPage.SignIn}`);
        })
        .catch((error: string) => {
          dispatch(
            openSnackbar({
              serverity: Serverity.Error,
              message: t(error),
            }),
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        register={register}
        name="email"
        label={t('auth.register.desc.email')}
        type="email"
        rule={{ required: true }}
        error={errors.email}
      />
      <FormInput
        register={register}
        name="password"
        label={t('auth.register.desc.password')}
        type="password"
        rule={{ required: true }}
        error={errors.password}
      />
      <FormInput
        register={register}
        name="confirmPassword"
        label={t('auth.register.desc.confirmPassword')}
        type="password"
        rule={{ required: true }}
        error={errors.confirmPassword}
      />
      <div className={style.buttonLayout}>
        <Button appearance="light" size="large" isLoading={isLoading} type="submit">
          {t('auth.register.act.register')}
        </Button>
      </div>
    </form>
  );
};

export default RegisterForm;
