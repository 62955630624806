import classNames from 'classnames';
import { HTMLAttributes, useRef, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useNavigate } from 'react-router-dom';
import Body from 'src/component/typography/Body';
import { MediaQuery } from 'src/constant/Media';
import { Dashboard } from 'src/constant/Page';
import IcAccount from 'src/image/dashboard/ic-account.svg';
import IcLocale from 'src/image/dashboard/ic-locale.svg';
import IcDown from 'src/image/ic-down-s.svg';
import IcLogo from 'src/image/ic-logo-bisa.svg';
import IcMenu from 'src/image/ic-menu.svg';
import NavbarLeftDrawer from './NavbarLeftDrawer';
import NavbarMenu from './NavbarMenu';
import NavbarRightDrawer from './NavbarRightDrawer';

type Props = HTMLAttributes<HTMLHeadingElement>;

const Navbar = ({ className, ...props }: Props) => {
  const navigate = useNavigate();
  const biggerThanMd = useMediaPredicate(MediaQuery.Md);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [rightDrawerOpen, setRightDrawerOpen] = useState<boolean>(false);
  const [leftDrawerOpen, setLeftDrawerOpen] = useState<boolean>(false);
  const ref = useRef(null);

  return (
    <>
      {biggerThanMd ? (
        <div className={classNames(className, 'flex gap-4 mx-[120px] xl:mx-[260px] items-center')}>
          <img src={IcLogo} className="cursor-pointer" />
          <div className="flex gap-5 flex-1">
            <div className="flex cursor-pointer">
              <Body bold size="m" className="text-primary-500">
                Merchant
              </Body>
              <img src={IcDown} />
            </div>
            <Body
              bold
              size="m"
              className="cursor-pointer"
              onClick={() => navigate(Dashboard.Root + Dashboard.Balance)}
            >
              Balance
            </Body>
            <Body
              bold
              size="m"
              className="cursor-pointer"
              onClick={() => navigate(Dashboard.Root + Dashboard.Address)}
            >
              Addresses
            </Body>
            <Body
              bold
              size="m"
              className="cursor-pointer"
              onClick={() => navigate(Dashboard.Root + Dashboard.Payout)}
            >
              Payouts
            </Body>
            <Body
              bold
              size="m"
              className="cursor-pointer"
              onClick={() => navigate(Dashboard.Root + Dashboard.Deposit)}
            >
              Deposits
            </Body>
            <Body
              bold
              size="m"
              className="cursor-pointer"
              onClick={() => navigate(Dashboard.Root + Dashboard.Withdrawal)}
            >
              Withdrawals
            </Body>
            <Body
              bold
              size="m"
              className="cursor-pointer"
              onClick={() => navigate(Dashboard.Root + Dashboard.Settings)}
            >
              Settings
            </Body>
          </div>
          <img
            src={IcAccount}
            className="cursor-pointer"
            onClick={() => setIsMenuOpen(true)}
            ref={ref}
          />
          <img src={IcLocale} className="cursor-pointer" />
        </div>
      ) : (
        <div className={classNames(className, 'flex mx-4 justify-between items-center')} {...props}>
          <img src={IcMenu} className="cursor-pointer" onClick={() => setLeftDrawerOpen(true)} />
          <img src={IcLogo} className="cursor-pointer" />
          <img
            src={IcAccount}
            className="cursor-pointer"
            onClick={() => setRightDrawerOpen(true)}
          />
        </div>
      )}
      <NavbarLeftDrawer open={leftDrawerOpen} onClose={() => setLeftDrawerOpen(false)} />
      <NavbarRightDrawer open={rightDrawerOpen} onClose={() => setRightDrawerOpen(false)} />
      <NavbarMenu open={isMenuOpen} onClose={() => setIsMenuOpen(false)} anchorEl={ref.current} />
    </>
  );
};

export default Navbar;
