import { CircularProgress } from '@mui/material';
import classnames from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import style from './Button.module.scss';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  appearance?: 'light' | 'dark' | 'border' | 'text';
  size?: 'large' | 'medium' | 'small';
  isLoading?: boolean;
  className?: string;
};

const Button = ({
  appearance = 'light',
  size = 'medium',
  isLoading = false,
  disabled,
  className,
  children,
  ...props
}: ButtonProps) => (
  <div className={classnames(className, style.self)}>
    <button
      className={classnames(style.button, style[size], {
        [style.light]: appearance === 'light',
        [style.dark]: appearance === 'dark',
        [style.outlined]: appearance === 'border',
        [style.text]: appearance === 'text',
      })}
      disabled={isLoading || disabled}
      {...props}
    >
      <div className={classnames({ [style.invisible]: isLoading })}>{children}</div>
    </button>
    <div className={style.progressWrapper}>
      {isLoading && <CircularProgress classes={{ root: style.progress }} />}
    </div>
  </div>
);

export default Button;
