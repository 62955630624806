import { format } from 'date-fns';
import Body from 'src/component/typography/Body';
import H6 from 'src/component/typography/H6';
import { Address } from 'src/model/Address';

export type Props = {
  data: Address[];
};

const Card = ({ data }: Props) => (
  <>
    {data.map((address: Address) => (
      <div key={address.id} className="p-6 bg-white rounded-lg">
        <div className="px-3 pb-6">
          <Body size="s" className="text-grey-500">
            ID
          </Body>
          <H6 className="text-grey-700">{address.id}</H6>
        </div>
        <div className="flex">
          <div className="px-3 pb-6 w-1/2">
            <Body size="s" className="text-grey-500">
              Currency
            </Body>
            <H6 className="text-grey-700">{address.currency.toUpperCase()}</H6>
          </div>
          <div className="px-3 pb-6 w-1/2">
            <Body size="s" className="text-grey-500">
              Address
            </Body>
            <H6 className="text-grey-700">{address.address}</H6>
          </div>
        </div>
        <div className="flex">
          <div className="px-3 pb-6 w-1/2">
            <Body size="s" className="text-grey-500">
              Created Time
            </Body>
            <H6 className="text-grey-700">
              {format(new Date(address.createdAt), 'yyyy/MM/dd HH:mm:ss')}
            </H6>
          </div>
          <div className="px-3 pb-6 w-1/2">
            <Body size="s" className="text-grey-500">
              Updated Time
            </Body>
            <H6 className="text-grey-700">
              {format(new Date(address.updatedAt), 'yyyy/MM/dd HH:mm:ss')}
            </H6>
          </div>
        </div>
      </div>
    ))}
  </>
);

export default Card;
