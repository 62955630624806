import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency } from 'src/model/Currency';

export type PublicState = {
  currencies: Currency[] | null;
};

const initialState: PublicState = {
  currencies: null,
};

export const publicSlice = createSlice({
  name: 'public',
  initialState,
  reducers: {
    updateCurrencies: (state: PublicState, action: PayloadAction<Currency[]>) => {
      state.currencies = action.payload;
    },
  },
});

export const { updateCurrencies } = publicSlice.actions;
export default publicSlice.reducer;
