import { useTranslation } from 'react-i18next';
import H2 from 'src/component/typography/H2';
import SignInForm from './SignInForm';

const SignIn = () => {
  const { t } = useTranslation();

  return (
    <div className="mx-4 mt-10 xs:mx-auto xs:w-[560px]">
      <H2>{t('auth.signIn.signIn')}</H2>
      <SignInForm />
    </div>
  );
};

export default SignIn;
