import { useNavigate } from 'react-router-dom';
import Body from 'src/component/typography/Body';
import IcBack from 'src/image/ic-back.svg';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div className="flex cursor-pointer w-fit" onClick={() => navigate(-1)}>
      <img src={IcBack} />
      <Body bold className="text-primary-500">
        Back
      </Body>
    </div>
  );
};

export default BackButton;
