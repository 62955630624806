import classNames from 'classnames';
import { InputHTMLAttributes, Ref } from 'react';
import { FieldError, FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';
import style from './FormInput.module.scss';
import Input from './Input';

type FormInputProps<T> = InputHTMLAttributes<HTMLInputElement> & {
  type: 'text' | 'number' | 'password' | 'email';
  label?: string;
  name: Path<T>;
  register: UseFormRegister<T>;
  error?: FieldError;
  help?: string;
  autoComplete?: 'on' | 'off';
  disabled?: boolean;
  isAsterisk?: boolean;
  rule?: RegisterOptions;
  ref?: Ref<HTMLDivElement>;
};

const FormInput = <T extends FieldValues>({
  type,
  label,
  name,
  register,
  error,
  help,
  autoComplete = 'off',
  disabled = false,
  isAsterisk = false,
  rule = {},
  ref,
  className,
  ...props
}: FormInputProps<T>) => (
  <div className={classNames(style.self, className)} ref={ref}>
    {label !== undefined && (
      <div className={classNames(style.label, { [style.disabled]: disabled !== false })}>
        {label} {isAsterisk && <div className={style.labelAsterisk}>*</div>}
      </div>
    )}
    <Input
      {...register(name, rule)}
      error={error !== undefined}
      type={type}
      autoComplete={autoComplete}
      disabled={disabled}
      {...props}
    />
    {error?.message && <div className={style.errorMessage}>{error.message}</div>}
    {help !== undefined && <div className={style.help}>{help}</div>}
  </div>
);

export default FormInput;
