import { useNavigate } from 'react-router-dom';
import Drawer, { DrawerProps } from 'src/component/Drawer';
import Body from 'src/component/typography/Body';
import { Dashboard } from 'src/constant/Page';
import IcClose from 'src/image/ic-close.svg';
import IcDown from 'src/image/ic-down-s.svg';

type NavbarLeftDrawerProps = DrawerProps & {
  onClose: () => void;
};

const NavbarLeftDrawer = ({ open, onClose }: NavbarLeftDrawerProps) => {
  const navigate = useNavigate();

  const onClick = (path: string) => () => {
    navigate(Dashboard.Root + path);
    onClose();
  };

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <img className="ml-auto w-[30px] h-[30px]" onClick={onClose} src={IcClose} />
      <div className="flex px-2 py-4">
        <Body bold size="m" className="text-primary-500">
          Merchant
        </Body>
        <img src={IcDown} />
      </div>
      <Body bold size="m" className="px-2 py-4" onClick={onClick(Dashboard.Balance)}>
        Balance
      </Body>
      <Body bold size="m" className="px-2 py-4" onClick={onClick(Dashboard.Address)}>
        Addresses
      </Body>
      <Body bold size="m" className="px-2 py-4" onClick={onClick(Dashboard.Payout)}>
        Payouts
      </Body>
      <Body bold size="m" className="px-2 py-4" onClick={onClick(Dashboard.Deposit)}>
        Deposits
      </Body>
      <Body bold size="m" className="px-2 py-4" onClick={onClick(Dashboard.Withdrawal)}>
        Withdrawals
      </Body>
      <Body bold size="m" className="px-2 py-4" onClick={onClick(Dashboard.Settings)}>
        Settings
      </Body>
    </Drawer>
  );
};

export default NavbarLeftDrawer;
