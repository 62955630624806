import { format } from 'date-fns';
import Divider from 'src/component/Divider';
import Body from 'src/component/typography/Body';
import Subtitle from 'src/component/typography/Subtitle';
import { Address } from 'src/model/Address';

export type Props = {
  data: Address[];
};

const Table = ({ data }: Props) => {
  const elementsMapFunction = (address: Address) => (
    <div key={address.id}>
      <div className="flex">
        <Body size="m" className="px-3 py-4 w-1/5 break-words">
          {address.id}
        </Body>
        <Body size="m" className="px-3 py-4 w-1/5">
          {address.currency.toUpperCase()}
        </Body>
        <Body size="m" className="px-3 py-4 w-1/5 break-words">
          {address.address}
        </Body>
        <Body size="m" className="px-3 py-4 w-1/5">
          {format(new Date(address.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </Body>
        <Body size="m" className="px-3 py-4 w-1/5">
          {format(new Date(address.updatedAt), 'yyyy/MM/dd HH:mm:ss')}
        </Body>
      </div>
      <Divider />
    </div>
  );

  return (
    <>
      <div className="flex">
        <Subtitle className="px-3 py-4 w-1/5">ID</Subtitle>
        <Subtitle className="px-3 py-4 w-1/5">Currency</Subtitle>
        <Subtitle className="px-3 py-4 w-1/5">Address</Subtitle>
        <Subtitle className="px-3 py-4 w-1/5">Created Time</Subtitle>
        <Subtitle className="px-3 py-4 w-1/5">Updated Time</Subtitle>
      </div>
      <Divider />
      {data.map(elementsMapFunction)}
    </>
  );
};

export default Table;
