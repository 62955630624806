import Slide, { SlideProps } from '@mui/material/Slide';
import MuiSnackbar from '@mui/material/Snackbar';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Serverity } from 'src/constant/Notification';
import IcError from 'src/image/snackbar/ic-error.svg';
import IcInfo from 'src/image/snackbar/ic-info.svg';
import IcSuccess from 'src/image/snackbar/ic-success.svg';
import IcWarning from 'src/image/snackbar/ic-warning.svg';
import { dismissSnackbar } from 'src/redux/notificationSlice';
import { dispatch, RootState } from 'src/redux/store';

const Transition = (props: SlideProps) => <Slide {...props} direction="up" />;

const Snackbar = () => {
  const { open, message, serverity } = useSelector(
    (rootState: RootState) => rootState.notification,
  );

  const onClose = () => {
    dispatch(dismissSnackbar());
  };

  const Icon = {
    success: IcSuccess,
    info: IcInfo,
    warning: IcWarning,
    error: IcError,
  };

  return (
    <MuiSnackbar
      classes={{ root: '!left-4 !right-4 !bottom-10 !translate-x-0 xs:!bottom-[70px]' }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
      autoHideDuration={6000}
    >
      <div
        className={classnames(
          'bg-info-500 text-white w-screen max-w-[560px] p-4 rounded flex gap-3',
          {
            'bg-primary-600': serverity === Serverity.Success,
            'bg-info-500': serverity === Serverity.Info,
            'bg-warning-500 text-black-500': serverity === Serverity.Warning,
            'bg-alert-500': serverity === Serverity.Error,
          },
        )}
      >
        <img className="w-[21px] h-[21px]" src={serverity ? Icon[serverity] : IcInfo} />
        {message}
      </div>
    </MuiSnackbar>
  );
};

export default Snackbar;
