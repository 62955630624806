import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

const Subtitle = ({ className, ...props }: Props) => (
  <div className={classNames('text-xs leading-6 font-[600]', className)} {...props} />
);

export default Subtitle;
