import classnames from 'classnames';
import { format } from 'date-fns';
import { useState } from 'react';
import Button from 'src/component/Button';
import Body from 'src/component/typography/Body';
import H6 from 'src/component/typography/H6';
import IcArrowDown from 'src/image/dashboard/ic-arrow-down.svg';
import { Withdrawal } from 'src/model/Withdrawal';

export type Props = {
  data: Withdrawal[];
  onAccept: (id: string) => () => void;
  onReject: (id: string) => () => void;
};

const Card = ({ data, onAccept, onReject }: Props) => {
  const [expandedItemList, setExpandedItemList] = useState<Set<string>>(new Set([]));

  const onDetailsClick = (id: string) => () => {
    if (expandedItemList.has(id))
      setExpandedItemList(new Set([...expandedItemList].filter((i) => i !== id)));
    else setExpandedItemList(new Set([...expandedItemList, id]));
  };

  return (
    <>
      {data.map((withdrawal: Withdrawal) => (
        <div key={withdrawal.id} className="p-6 bg-white rounded-lg">
          <div className="px-3 pb-6">
            <Body size="s" className="text-grey-500">
              ID
            </Body>
            <H6 className="text-grey-700">{withdrawal.id}</H6>
          </div>
          <div className="flex">
            <div className="px-3 pb-6 w-1/2">
              <Body size="s" className="text-grey-500">
                Currency
              </Body>
              <H6 className="text-grey-700">{withdrawal.currency.toUpperCase()}</H6>
            </div>
            <div className="px-3 pb-6 w-1/2">
              <Body size="s" className="text-grey-500">
                Amount
              </Body>
              <H6 className="text-grey-700">{withdrawal.amount}</H6>
            </div>
          </div>
          <div className="flex">
            <div className="px-3 pb-6 w-1/2">
              <Body size="s" className="text-grey-500">
                Created Time
              </Body>
              <H6 className="text-grey-700">
                {format(new Date(withdrawal.createdAt), 'yyyy/MM/dd HH:mm:ss')}
              </H6>
            </div>
            <div className="px-3 pb-6 w-1/2">
              <Body size="s" className="text-grey-500">
                Confirmed Time
              </Body>
              <H6 className="text-grey-700">
                {withdrawal.confirmedAt
                  ? format(new Date(withdrawal.confirmedAt), 'yyyy/MM/dd HH:mm:ss')
                  : ''}
              </H6>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <img
              src={IcArrowDown}
              className={classnames('transition-all duration-200 cursor-pointer', {
                'rotate-180': expandedItemList.has(withdrawal.id),
              })}
              onClick={onDetailsClick(withdrawal.id)}
            />
          </div>
          <div
            className={classnames('transition-[max-height] duration-[400ms] overflow-hidden', {
              'max-h-0': !expandedItemList.has(withdrawal.id),
              'max-h-80': expandedItemList.has(withdrawal.id),
            })}
          >
            <div className="bg-light-200 p-3 rounded-lg mt-4">
              <div className="mb-3">
                <Body size="s" className="text-grey-500">
                  Transaction Hash
                </Body>
                <H6 className="text-grey-700">{withdrawal.transaction}</H6>
              </div>
              <div className="mb-3 flex gap-3">
                <Button size="s" onClick={onAccept(withdrawal.id)}>
                  放行提領
                </Button>
                <Button size="s" appearance="secondary" onClick={onReject(withdrawal.id)}>
                  拒絕提領
                </Button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Card;
