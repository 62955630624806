export enum Welcome {
  Root = '/welcome/',
  OrderMode = 'order-mode',
  AccountMode = 'account-mode',
  WithdrawalProcess = 'withdrawal-process',
  FeesPricing = 'fees-pricing',
  ContactUs = 'contact-us',
}

export enum Dashboard {
  Root = '/dashboard/',
  Balance = 'balance',
  Withdraw = 'balance/withdraw',
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
  Address = 'address',
  Payout = 'payout',
  Settings = 'settings',
}

export enum Auth {
  Root = '/auth/',
  SignIn = 'sign-in',
  SignOut = 'sign-out',
  Register = 'register',
  ForgotPassword = 'forgot-password',
  ResetPassword = 'reset-password',
}

export enum Transaction {
  Root = '/t',
  Payment = 'payment',
}
