import { useTranslation } from 'react-i18next';
import H2 from 'src/component/typography/H2';

const ResetPassword = () => {
  const { t } = useTranslation();

  return (
    <div className="mx-4 mt-10 xs:mx-auto xs:w-[560px]">
      <H2>{t('auth.resetPassword.title')}</H2>
    </div>
  );
};

export default ResetPassword;
