import balanceEndpoint from 'src/api/balanceEndpoint';
import { ParameterState, updateParametersOfAll } from 'src/redux/parameterSlice';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';

export const getBalances = async () => {
  try {
    dispatch(startWaiting());
    const account = sessionStorage.getItem('account') ?? '';
    const res = await balanceEndpoint.getBalances(account);

    const parameters: ParameterState['parameters'] = {};
    res.data.forEach((v) => {
      parameters[v.currency] = {
        freezeLow: v.freezeLow,
        freezeHigh: v.freezeHigh,
        payoutAmount: v.payoutAmount,
        withdrawalReviewAmount: v.withdrawalReviewAmount,
      };
    });

    dispatch(updateParametersOfAll(parameters));

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};
