import { useTranslation } from 'react-i18next';
import H2 from 'src/component/typography/H2';
import RegisterForm from './component/RegisterForm';

const Register = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mx-4 mt-10 xs:mx-auto xs:w-[560px]">
        <H2>{t('dashboard:auth.register.heading')}</H2>
        <RegisterForm />
      </div>
    </>
  );
};

export default Register;
