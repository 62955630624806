import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

const LayoutDashboard = ({ className, ...props }: Props) => (
  <div className={className}>
    <div className="mt-10 mx-4 pt-10 pb-16 xs:mx-10 sm:mx-[120px] xl:mx-[260px]" {...props} />
  </div>
);

export default LayoutDashboard;
