import Body from 'src/component/typography/Body';

const Footer = () => (
  <div className="xs:py-6 flex flex-col xs:flex-row items-center justify-center text-grey-500 xs:gap-4">
    <Body size="m" className="py-2 xs:py-0">
      Temrs & Conditions
    </Body>
    <div className="hidden xs:block w-[1px] h-[21px] bg-grey-500" />
    <Body size="m" className="py-2 xs:py-0">
      Privacy Policy
    </Body>
  </div>
);

export default Footer;
