import {
  GetWithdrawalsParams,
  GetWithdrawalsResponse,
  PatchWithdrawalAcceptRequest,
  PatchWithdrawalRejectRequest,
  PostWithdrawalsRequest,
  PostWithdrawalsResponse,
} from 'src/model/Api';
import { handleError } from 'src/util/errorHandler';
import http from 'src/util/http';

const getWithdrawals = async (account: string, currency: string, params?: GetWithdrawalsParams) => {
  try {
    return await http.authGet<GetWithdrawalsResponse>(`${account}/${currency}/withdrawals`, {
      params,
    });
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

const postWithdrawals = async (account: string, currency: string, data: PostWithdrawalsRequest) => {
  try {
    return await http.authPost<PostWithdrawalsResponse, PostWithdrawalsRequest>(
      `${account}/${currency}/withdrawals`,
      {
        data,
      },
    );
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

const patchWithdrawalAccept = async (
  account: string,
  currency: string,
  withdrawalId: string,
  data: PatchWithdrawalAcceptRequest,
) => {
  try {
    return await http.authPatch<void, PatchWithdrawalAcceptRequest>(
      `${account}/${currency}/withdrawals/${withdrawalId}/accept`,
      { data },
    );
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

const patchWithdrawalReject = async (
  account: string,
  currency: string,
  withdrawalId: string,
  data: PatchWithdrawalRejectRequest,
) => {
  try {
    return await http.authPatch<void, PatchWithdrawalRejectRequest>(
      `${account}/${currency}/withdrawals/${withdrawalId}/reject`,
      { data },
    );
  } catch (e) {
    const error = handleError(e);
    throw error.message;
  }
};

export default {
  getWithdrawals,
  postWithdrawals,
  patchWithdrawalAccept,
  patchWithdrawalReject,
};
