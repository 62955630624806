import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';

const Transaction = () => (
  <div className="min-h-screen bg-light-200">
    <Navbar className="h-[72px]" />
    <Outlet />
    <Footer />
  </div>
);

export default Transaction;
