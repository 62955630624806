import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RootState } from 'src/redux/store';
import {
  Auth as AuthPage,
  Dashboard as DashboardPage,
  Transaction as TransactionPage,
  Welcome as WelcomePage,
} from './constant/Page';
import Auth from './feature/auth';
import Dashboard from './feature/dashboard';
import Transaction from './feature/transaction';
import Welcome from './feature/welcome';
import ForgotPassword from './page/auth/forgotPassword';
import Register from './page/auth/register';
import ResetPassword from './page/auth/resetPassword';
import SignIn from './page/auth/signIn';
import SignOut from './page/auth/signOut';
import Address from './page/dashboard/address';
import Balance from './page/dashboard/balance';
import Deposit from './page/dashboard/deposit';
import Payout from './page/dashboard/payout';
import Settings from './page/dashboard/settings';
import Withdraw from './page/dashboard/withdraw';
import Withdrawal from './page/dashboard/withdrawal';
import Payment from './page/transaction/payment';
import Account from './page/welcome/account';
import ContactUs from './page/welcome/contactUs';
import Fees from './page/welcome/fees';
import Landing from './page/welcome/landing';
import Order from './page/welcome/order';
import WithdrawalInfo from './page/welcome/withdrawal';

const AppRoutes = () => {
  const { isLoggedIn } = useSelector((rootState: RootState) => rootState.auth);

  return (
    <Routes>
      <Route path={WelcomePage.Root} element={<Welcome />}>
        <Route path="" element={<Landing />} />
        <Route path={WelcomePage.OrderMode} element={<Order />} />
        <Route path={WelcomePage.AccountMode} element={<Account />} />
        <Route path={WelcomePage.WithdrawalProcess} element={<WithdrawalInfo />} />
        <Route path={WelcomePage.FeesPricing} element={<Fees />} />
        <Route path={WelcomePage.ContactUs} element={<ContactUs />} />
      </Route>

      <Route path={TransactionPage.Root} element={<Transaction />}>
        <Route path={TransactionPage.Payment} element={<Payment />} />
      </Route>

      {isLoggedIn ? (
        <Route path={AuthPage.Root} element={<Auth />}>
          <Route path="" element={<Navigate to={WelcomePage.Root} />} />
          <Route path={AuthPage.ResetPassword} element={<ResetPassword />} />
          <Route path={AuthPage.SignOut} element={<SignOut />} />
        </Route>
      ) : (
        <Route path={AuthPage.Root} element={<Auth />}>
          <Route path="" element={<Navigate to={WelcomePage.Root} />} />
          <Route path={AuthPage.SignIn} element={<SignIn />} />
          <Route path={AuthPage.ForgotPassword} element={<ForgotPassword />} />
          <Route path={AuthPage.Register} element={<Register />} />
        </Route>
      )}
      {!isLoggedIn ? (
        <Route
          path={`${DashboardPage.Root}/*`}
          element={<Navigate to={`${AuthPage.Root}${AuthPage.SignIn}`} replace />}
        />
      ) : (
        <Route path={DashboardPage.Root} element={<Dashboard />}>
          <Route path="" element={<Navigate to={WelcomePage.Root} />} />
          <Route path={DashboardPage.Balance} element={<Balance />} />
          <Route path={DashboardPage.Withdraw} element={<Withdraw />} />
          <Route path={DashboardPage.Deposit} element={<Deposit />} />
          <Route path={DashboardPage.Withdrawal} element={<Withdrawal />} />
          <Route path={DashboardPage.Address} element={<Address />} />
          <Route path={DashboardPage.Payout} element={<Payout />} />
          <Route path={DashboardPage.Settings} element={<Settings />} />
        </Route>
      )}
      <Route path="/*" element={<Navigate to={WelcomePage.Root} />} />
    </Routes>
  );
};

export default AppRoutes;
