import { useTranslation } from 'react-i18next';
import Layout from 'src/component/LayoutWelcome';
import Body from 'src/component/typography/Body';
import H1 from 'src/component/typography/H1';
import H3 from 'src/component/typography/H3';
import H4 from 'src/component/typography/H4';
import Ic1 from 'src/image/welcome/ic-how-withdrawal-step-1.svg';
import Ic2 from 'src/image/welcome/ic-how-withdrawal-step-2.svg';
import Ic3 from 'src/image/welcome/ic-how-withdrawal-step-3.svg';

const Withdrawal = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="pt-[70px] text-center">
        <H1>{t('welcome:withdrawalProcess.heading')}</H1>
        <H3 className="mt-[30px]">{t('welcome:withdrawalProcess.desc.headingDesc')}</H3>
      </div>
      <div className="flex items-center sm:items-start flex-col sm:flex-row py-10">
        <div className="xs:w-[560px] sm:w-1/2 sm:px-10">
          <img src={Ic1} className="w-full" />
        </div>
        <div className="pt-5 xs:pt-10 xs:w-[560px] sm:w-1/2 sm:p-10">
          <H4>{t('welcome:withdrawalProcess.desc.stepOne')}</H4>
          <Body className="text-grey-700 mt-[10px]">
            {t('welcome:withdrawalProcess.desc.stepOneDesc')}
          </Body>
        </div>
      </div>
      <div className="flex items-center sm:items-start flex-col sm:flex-row py-10">
        <div className="xs:w-[560px] sm:w-1/2 sm:px-10 sm:order-last">
          <img src={Ic2} className="w-full" />
        </div>
        <div className="pt-5 xs:pt-10 xs:w-[560px] sm:w-1/2 sm:p-10">
          <H4>{t('welcome:withdrawalProcess.desc.stepTwo')}</H4>
          <Body className="text-grey-700 mt-[10px]">
            {t('welcome:withdrawalProcess.desc.stepTwoDesc')}
          </Body>
        </div>
      </div>
      <div className="flex items-center sm:items-start flex-col sm:flex-row py-10">
        <div className="xs:w-[560px] sm:w-1/2 sm:px-10">
          <img src={Ic3} className="w-full" />
        </div>
        <div className="pt-5 xs:pt-10 xs:w-[560px] sm:w-1/2 sm:p-10">
          <H4>{t('welcome:withdrawalProcess.desc.stepThree')}</H4>
          <Body className="text-grey-700 mt-[10px]">
            {t('welcome:withdrawalProcess.desc.stepThreeDesc')}
          </Body>
        </div>
      </div>
    </Layout>
  );
};

export default Withdrawal;
