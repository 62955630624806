import classNames from 'classnames';
import { InputHTMLAttributes } from 'react';
import IcSearch from 'src/image/dashboard/ic-search.svg';
import Input from './Input';

const SearchInput = ({ className, ...props }: InputHTMLAttributes<HTMLInputElement>) => (
  <div className={classNames('relative', className)}>
    <Input className="pr-8 w-full" {...props} />
    <img className="absolute bottom-2 right-2" src={IcSearch} />
  </div>
);

export default SearchInput;
