import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch } from 'react-redux';
import Autocomplete from 'src/component/Autocomplete';
import DateFilter from 'src/component/DateFilter';
import Layout from 'src/component/LayoutDashboard';
import Pagination from 'src/component/Pagination';
import SearchInput from 'src/component/SearchInput';
import H2 from 'src/component/typography/H2';
import { MediaQuery } from 'src/constant/Media';
import { Serverity } from 'src/constant/Notification';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import PicNoData from 'src/image/dashboard/pic-nodata.svg';
import { Currency } from 'src/model/Currency';
import { Payout as PayoutType } from 'src/model/Payout';
import { openSnackbar } from 'src/redux/notificationSlice';
import { getCurrencies, getPayoutsOfCurrency } from 'src/service/payoutService';
import Card from './Card';
import Table from './Table';

const Payout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isBiggerThanSm = useMediaPredicate(MediaQuery.Sm);
  const [currencies, setCurrencies] = useState<Currency[]>();
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);
  const [payouts, setPayouts] = useState<PayoutType[]>();
  const { limit, offset, setCount, paginationProps } = usePagination();
  const { begin, end, dateFilterProps } = useDateFilter();

  useEffect(() => {
    getCurrencies()
      .then((res) => setCurrencies(res))
      .catch((error) =>
        dispatch(
          openSnackbar({
            serverity: Serverity.Error,
            message: t(error),
          }),
        ),
      );
  }, []);

  useEffect(() => {
    if (selectedCurrency === null) return;
    getPayoutsOfCurrency(selectedCurrency.toLowerCase(), { limit, skip: offset, begin, end }).then(
      (res) => {
        setPayouts(res.data);
        setCount(res.count);
      },
    );
  }, [limit, offset, begin, end, selectedCurrency]);

  return (
    <Layout>
      <H2>Payouts</H2>
      <div className="mt-4 flex flex-col sm:flex-row justify-between gap-6">
        <DateFilter {...dateFilterProps} />
        <div className="flex gap-6 items-end">
          <Autocomplete
            options={(currencies ?? []).map((currency: Currency) => currency.id.toUpperCase())}
            label="Currency"
            className="w-1/3 sm:w-auto"
            placeholder="Select a currency"
            setValue={setSelectedCurrency}
            value={selectedCurrency}
          />
          <SearchInput placeholder="ID, Client ID, Address" className="w-2/3 sm:w-auto" />
        </div>
      </div>
      {payouts?.length === 0 && (
        <div className="text-center mt-[70px] text-grey-500">
          <img src={PicNoData} className="w-fit mx-auto" />
          <div>{t('desc.noRecord')}</div>
        </div>
      )}
      {payouts && payouts.length > 0 && (
        <div>
          <Pagination className="mt-6 justify-end" {...paginationProps} />
          <div className="mt-4">
            {isBiggerThanSm ? <Table data={payouts} /> : <Card data={payouts} />}
          </div>
          <Pagination className="mt-4 justify-end" {...paginationProps} />
        </div>
      )}
    </Layout>
  );
};

export default Payout;
