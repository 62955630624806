import withdrawalEndpoint from 'src/api/withdrawalEndpoint';
import { GetWithdrawalsParams } from 'src/model/Api';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';
import { loadCurrencies } from './commonService';

export const getCurrencies = async () => await loadCurrencies();

export const getWithdrawalsOfCurrency = async (currency: string, params?: GetWithdrawalsParams) => {
  try {
    dispatch(startWaiting());
    const account = sessionStorage.getItem('account') ?? '';
    const res = await withdrawalEndpoint.getWithdrawals(account, currency, params);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const acceptWithdrawal = async (currency: string, withdrawalId: string) => {
  try {
    dispatch(startWaiting());
    const account = sessionStorage.getItem('account') ?? '';
    const res = await withdrawalEndpoint.patchWithdrawalAccept(account, currency, withdrawalId, {
      timestamp: Date.now(),
    });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const rejectWithdrawal = async (currency: string, withdrawalId: string) => {
  try {
    dispatch(startWaiting());
    const account = sessionStorage.getItem('account') ?? '';
    const res = await withdrawalEndpoint.patchWithdrawalReject(account, currency, withdrawalId, {
      timestamp: Date.now(),
    });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};
