import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Autocomplete from 'src/component/Autocomplete';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Layout from 'src/component/LayoutDashboard';
import H2 from 'src/component/typography/H2';
import { Serverity } from 'src/constant/Notification';
import { Currency } from 'src/model/Currency';
import { WithdrawForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/notificationSlice';
import { createWithdrawal, getCurrencies } from 'src/service/withdrawService';

const Withdraw = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm<WithdrawForm>();
  const location = useLocation();
  const state = location.state as { currency: string } | null;
  const [currencies, setCurrencies] = useState<Currency[]>();
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(
    state?.currency.toUpperCase() ?? null,
  );

  useEffect(() => {
    getCurrencies()
      .then((res) => setCurrencies(res))
      .catch((error) =>
        dispatch(
          openSnackbar({
            serverity: Serverity.Error,
            message: t(error),
          }),
        ),
      );
  }, []);

  const onSubmit = (data: WithdrawForm) => {
    if (selectedCurrency === null) return;
    createWithdrawal(selectedCurrency, data.amount, data.address)
      .then(() => navigate(-1))
      .catch((error) =>
        dispatch(
          openSnackbar({
            serverity: Serverity.Error,
            message: t(error),
          }),
        ),
      );
  };

  return (
    <Layout>
      <BackButton />
      <H2 className="my-3">Withdraw</H2>
      <Form className="bg-white rounded-lg p-4 sm:p-6" methods={methods} onSubmit={onSubmit}>
        <div className="max-w-[560px] mx-auto">
          <div className="pt-4 pb-2">
            <Autocomplete
              options={(currencies ?? []).map((currency: Currency) => currency.id.toUpperCase())}
              label="Currency"
              asterisk
              setValue={setSelectedCurrency}
              value={selectedCurrency}
            />
          </div>
          <div className="pt-4 pb-2">
            <FormInput name="amount" label="Amount" type="number" required asterisk />
          </div>
          <div className="pt-4 pb-2">
            <FormInput name="address" label="Address" required asterisk />
          </div>
          <div className="pt-4 pb-2">
            <FormInput name="callback" label="Callback URL" />
          </div>
          <div className="pt-10 flex justify-end">
            <Button type="submit">Submit</Button>
          </div>
        </div>
      </Form>
    </Layout>
  );
};

export default Withdraw;
