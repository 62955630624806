import authEndpoint from 'src/api/authEndpoint';
import balanceEndpoint from 'src/api/balanceEndpoint';
import { PatchBalancesSetRequest, PatchBalancesUnsetRequest } from 'src/model/Api';
import { ParameterForm } from 'src/model/Form';
import { updateParametersOfCurrency } from 'src/redux/parameterSlice';
import { dispatch, getState } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';
import { loadCurrencies } from './commonService';

export const getCurrencies = async () => await loadCurrencies();

export const resetPassword = async (oldPwd: string, newPwd: string) => {
  try {
    dispatch(startWaiting());
    const account = sessionStorage.getItem('account') ?? 'x';
    await authEndpoint.resetPassword(account, {
      old: oldPwd,
      new: newPwd,
      timestamp: Date.now(),
    });
  } finally {
    dispatch(finishWaiting());
  }
};

export const getParameterOfCurrency = async (currency: string) => {
  const state = getState();
  if (state.parameter.parameters[currency] !== undefined)
    return state.parameter.parameters[currency];

  try {
    dispatch(startWaiting());
    const account = sessionStorage.getItem('account') ?? 'x';
    const res = await balanceEndpoint.getBalance(account, currency);

    dispatch(
      updateParametersOfCurrency({
        currency,
        parameter: {
          freezeLow: res.data.freezeLow,
          freezeHigh: res.data.freezeHigh,
          payoutAmount: res.data.payoutAmount,
          withdrawalReviewAmount: res.data.withdrawalReviewAmount,
        },
      }),
    );

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const saveParameter = async (currency: string, data: ParameterForm) => {
  try {
    dispatch(startWaiting());

    const state = getState();
    const parameters = state.parameter.parameters[currency];

    const setRequest: PatchBalancesSetRequest = { timestamp: Date.now() };
    const unsetRequest: PatchBalancesUnsetRequest = { timestamp: Date.now() };

    if (data.freezeLow !== parameters.freezeLow)
      if (data.freezeLow.length > 0) setRequest.freezeLow = data.freezeLow;
      else unsetRequest.freezeLow = true;

    if (data.freezeHigh !== parameters.freezeHigh)
      if (data.freezeHigh.length > 0) setRequest.freezeHigh = data.freezeHigh;
      else unsetRequest.freezeHigh = true;

    if (data.payoutAmount !== parameters.payoutAmount)
      if (data.payoutAmount.length > 0) setRequest.payoutAmount = data.payoutAmount;
      else unsetRequest.payoutAmount = true;

    if (data.withdrawalReviewAmount !== parameters.withdrawalReviewAmount)
      if (data.withdrawalReviewAmount.length > 0)
        setRequest.withdrawalReviewAmount = data.withdrawalReviewAmount;
      else unsetRequest.withdrawalReviewAmount = true;

    const account = sessionStorage.getItem('account') ?? 'x';
    await Promise.all([
      balanceEndpoint.patchBalancesSet(account, currency, setRequest),
      balanceEndpoint.patchBalancesUnset(account, currency, unsetRequest),
    ]);
  } finally {
    dispatch(finishWaiting());
  }
};
