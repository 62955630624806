import { useTranslation } from 'react-i18next';
import Calendar from 'src/component/Calendar';
import { DateRange } from 'src/model/DateFilter';

type Props = {
  fromDate: Date;
  toDate: Date;
  setDateRange: (dateRange: DateRange) => void;
  onChange?: () => void;
};

const DateFilter = ({ fromDate, toDate, setDateRange, onChange }: Props) => {
  const { t } = useTranslation();

  const onCalendarChanged = (dateType: 'from' | 'to') => (newDate: Date) => {
    const newDateRange = {
      from: dateType === 'from' || newDate.getTime() < fromDate.getTime() ? newDate : undefined,
      to: dateType === 'to' || newDate.getTime() > toDate.getTime() ? newDate : undefined,
    };
    onChange && onChange();
    setDateRange(newDateRange);
  };

  return (
    <div className="flex justify-between gap-6">
      <Calendar
        label={t('desc.from')}
        defaultDate={fromDate}
        valueDate={fromDate}
        onDateChanged={onCalendarChanged('from')}
        tailIcon
        className="w-1/2 sm:w-[216px]"
      />
      <Calendar
        label={t('desc.to')}
        defaultDate={toDate}
        valueDate={toDate}
        onDateChanged={onCalendarChanged('to')}
        tailIcon
        className="w-1/2 sm:w-[216px]"
      />
    </div>
  );
};

export default DateFilter;
