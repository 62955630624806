import payoutEndpoint from 'src/api/payoutEndpoint';
import { GetPayoutsParams } from 'src/model/Api';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';
import { loadCurrencies } from './commonService';

export const getCurrencies = async () => await loadCurrencies();

export const getPayoutsOfCurrency = async (currency: string, params?: GetPayoutsParams) => {
  try {
    dispatch(startWaiting());
    const account = sessionStorage.getItem('account') ?? '';
    const res = await payoutEndpoint.getPayouts(account, currency, params);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};
