import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Loader from './component/Loader';
import Snackbar from './component/Snackbar';
import ThemeProvider from './context/themer';
import { setLoggedIn } from './redux/authSlice';
import { configStore } from './redux/store';
import AppRoutes from './Routes';
import { getIsLoggedIn } from './service/authService';
import './style/index.scss';
import './util/i18n';
import './index.css';

const store = configStore();

const initStore = () => {
  store.dispatch(setLoggedIn(getIsLoggedIn()));
};
initStore();
const root = createRoot(document.getElementById('root') as Element);
root.render(
  <Provider store={store}>
    <ThemeProvider>
      <BrowserRouter>
        <AppRoutes />
        <Loader />
        <Snackbar />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
);
